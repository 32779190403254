import React from "react"
import { Redirect } from "react-router-dom"

// Dashboard
import DashboardIndex from "../pages/dashboard/Index"

import PosIndex from "../pages/pos/Index"
import PosCreateUpdate from "../pages/pos/CreateUpdate"

import CompanyIndex from "../pages/company/Index"
import CompanyCreateUpdate from "../pages/company/CreateUpdate"
import CompanyCars from "../pages/company/Cars"

import MyCompanyCreateUpdate from "../pages/my-comapny/CreateUpdate"
import MyCompanyCars from "../pages/my-comapny/Cars"

import Cheques from "../pages/cheques/Index"
import Services from "../pages/services/Index"
import ServicesCreateUpdate from "../pages/services/CreateUpdate"
import Clients from "../pages/clients/Index"
import ClientDetail from "../pages/clients/Detail"
import Purchases from "../pages/purchases/Index"
import Cashiers from "../pages/cashiers/Index"
import Directors from "../pages/directors/Index"

// Authentication related pages
import Login from "../pages/authentication/Login"
import Promotion from "../pages/authentication/Promotion"
import ForgotPassword from "../pages/authentication/ForgotPassword"
import Prize from "../pages/prize/Index"
import PrizeCreateUpdate from "../pages/prize/CreateUpdate"
import Debtors from "../pages/debtors/Index"
import DebtorDetail from "../pages/debtors/Detail"

import CashierCashbox from "../pages/cashbox/Index"
import CashierCheques from "../pages/cheques/CashierIndex"
import CashierClients from "../pages/clients/CashierIndex"
import CashierClientsDetail from "../pages/clients/CashierDetail"

import GasColumns from "../pages/gasColumns/Index"
import GasColumnsReport from "../pages/gasColumnsAdd/ReportIndex"
import Shift from "../pages/shifts/Index"
import CashierGasColumnsAdd from "../pages/gasColumnsAdd/CashierIndex"
import Documents from "../pages/documents/Index"
import Expenses from "../pages/expenses/Index"
import CashierExpenses from "../pages/expenses/CashierIndex"
import CashierXReport from "../pages/reports/CashierIndex"

const userRoutes = [
	{ path: "/dashboard", component: DashboardIndex },
	{ path: "/documents", component: Documents },
	{ path: "/expenses", component: Expenses },
	{ path: "/cashier-expenses", component: CashierExpenses },
	{ path: "/x-report", component: CashierXReport },

	{ path: "/pos", component: PosIndex },
	{ path: "/pos/create", component: PosCreateUpdate },
	{ path: "/pos/update/:id", component: PosCreateUpdate },

	{ path: "/company", component: CompanyIndex },
	{ path: "/company/create", component: CompanyCreateUpdate },
	{ path: "/company/update/:id", component: CompanyCreateUpdate },
	{ path: "/company/cars/:id", component: CompanyCars },

	{ path: "/my-company/company", component: MyCompanyCreateUpdate },
	{ path: "/my-company/cars/", component: MyCompanyCars },
	{ path: "/my-company/cars/create", component: MyCompanyCars },
	{ path: "/my-company/cars/update/:id", component: MyCompanyCreateUpdate },

	{ path: "/cheques", component: Cheques },
	{ path: "/services", component: Services },
	{ path: "/services/create", component: ServicesCreateUpdate },
	{ path: "/services/:id", component: ServicesCreateUpdate },
	{ path: "/clients", component: Clients },
	{ path: "/clients/:id", component: ClientDetail },
	{ path: "/purchases", component: Purchases },
	{ path: "/cashiers", component: Cashiers },
	{ path: "/directors", component: Directors },
	{ path: "/gas-columns", component: GasColumns },
	{ path: "/gas-columns-report", component: GasColumnsReport },
	{ path: "/shifts", component: Shift },
	{ path: "/debtors", component: Debtors },
	{ path: "/debtors/:posId/:userId", component: DebtorDetail },

	{ path: "/prize", component: Prize },
	{ path: "/prize/create", component: PrizeCreateUpdate },
	{ path: "/prize/update/:id", component: PrizeCreateUpdate },

	{ path: "/cashier-cashbox", component: CashierCashbox },
	{ path: "/cashier-cheques", component: CashierCheques },
	{ path: "/cashier-clients", component: CashierClients },
	{ path: "/cashier-clients/:id", component: CashierClientsDetail },
	{ path: "/cashier-gas-columns", component: CashierGasColumnsAdd },

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
	// 404 route not found
	{ path: "*", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
	{ path: "/promotion/:id", component: Promotion },
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgotPassword },
]

export { userRoutes, authRoutes }