import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation'

import { GET, POST, PUT, PGET } from 'helpers/api'
import { formatMoney, returnSign, findFromArrayById, formatDateBackend, formatDateWithTime } from "helpers/helpers"

function Index() {
	const role = localStorage.getItem('role');

	const { t } = useTranslation()

	const { register, handleSubmit, formState, control, setValue, getValues, reset } = useForm({
		mode: "onChange",
		resolver: yupResolver(validationSchema),
	});
	const { errors } = formState

	const [poses, setPoses] = useState([])
	const [data, setData] = useState([])
	const [total, setTotal] = useState(0)
	const [selectedItem, setSelectedItem] = useState({})
	const [showModal, setShowModal] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		posId: null,
		ageFrom: null,
		ageTo: null,
		gender: null,
		search: ''
	})
	const [pagination, setPagination] = useState({
		url: '/services/redeemapi/api/expenses-pageList',
		response: null
	})

	async function createUpdate(validatedData) {
		var selectedItemCopy = { ...validatedData }
		var response;
		if (selectedItemCopy.id) {
			response = await PUT('/services/redeemapi/api/expenses', selectedItemCopy, { loader: true })
		} else {
			response = await POST('/services/redeemapi/api/expenses', selectedItemCopy, { loader: true })
		}

		if (response) {
			getData()
			setShowModal(false)
		}
	}

	function openModal(item) {
		var itemCopy = { ...item }
		for (const [key, value] of Object.entries(itemCopy)) {
			setValue(key, value)
		}
		setSelectedItem(itemCopy)
		setShowModal(true)
	}

	function closeModal() {
		setShowModal(false)
		reset()
	}

	async function selectPos(option) {
		setValue('posId', option.id)
	}

	async function searchWithFilter() {
		var filterUrl = "/services/redeemapi/api/expenses-pageList"
		var urlParams = ""
		checkFilter()

		if (filterData.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterData.posId
		if (filterData.createdDate)
			urlParams += returnSign(urlParams) + 'createdDate=' + filterData.createdDate
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if ("/services/redeemapi/api/expenses-pageList" + urlParams !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await PGET(filterUrl, {}, { loader: true })
		const responseTotal = await GET("/services/redeemapi/api/expenses-total" + urlParams, {}, { loader: true })
		if (response) {
			setData(response.data)
			setTotal(responseTotal)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response })
		}

		setDropdownIsOpen(false)
	}

	async function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.createdDate === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function resetFilter() {
		setFilterData({
			posId: null,
			createdDate: null,
			search: ''
		})
		setFilterDataExist(false)
		getData()
	}

	async function getData() {
		const response = await PGET('/services/redeemapi/api/expenses-pageList', {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': '/services/redeemapi/api/expenses-pageList', 'response': response })
		}
	}

	async function getTotal() {
		const response = await GET('/services/redeemapi/api/expenses-total', {}, { loader: true })
		if (response) {
			setTotal(response)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(newPageData) {
		setData(newPageData)
	}

	async function getPoses() {
		const response = await GET('/services/redeemapi/api/pos-cashier-helper')
		if (response) {
			setPoses(response)
		}
	}

	useEffect(() => {
		getTotal()
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('EXPENSES')}
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary" onClick={() => {
						setSelectedItem({})
						setShowModal(true)
					}}>
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex justify-content-between mb-3">
						<div className="d-flex">
							<div className="me-2">
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('POSES')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.posId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('DATE')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.createdDate ? new Date(filterData.createdDate) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'createdDate': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter()}>{t('APPLY')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>

						<div className="vertical-center">
							<h4 className="text-end">
								<b className="me-3">{t('TOTAL_AMOUNT')}:</b>{formatMoney(total)}
							</h4>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
								{filterData.createdDate &&
									<span className="me-2">
										<b>{t('DATE')}: </b>
										<span>{filterData.createdDate}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>№ {t('NAME3')}</th>
									<th>{t('POS')}</th>
									<th className="text-center">{t('DATE')}</th>
									<th className="text-center">{t('AMOUNT')}</th>
									{role !== 'ROLE_CASHIER' &&
										<th className="text-center">{t('ACTION')}</th>
									}
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{item.rowNum} {item.name}</td>
										<td>{item.posName}</td>
										<td className="text-center">{formatDateWithTime(item.createdDate)}</td>
										<td className="text-center">{formatMoney(item.amountOut)}</td>
										{role !== 'ROLE_CASHIER' &&
											<td className="d-flex justify-content-center">
												<div className="table-action-button table-action-primary-button" onClick={() => openModal(item)}>
													<i className="uil-edit-alt"></i>
												</div>
											</td>
										}

									</tr>
								))
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{pagination.response &&
				<FilterPagination
					pagination={pagination}
					paginate={paginate}>
				</FilterPagination>
			}

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedItem.id ?
							<>{t('EDIT')}</>
							:
							<>{t('CREATE')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(createUpdate)} autoComplete="off">
						<div className="form-group">
							<label>{t('POS')}<span className="required-mark">*</span></label>
							<Controller
								name="posId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										menuPosition="absolute"
										options={poses}
										value={poses.find(option => option.id === getValues('posId')) || ''}
										onChange={(option) => selectPos(option)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>}
							/>
							<span className="text-danger text-sm">{errors.posId?.message}</span>
						</div>
						<div className="form-group">
							<label>{t('NAME3')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="name" {...register('name')} />
							<span className="text-danger text-sm">{errors.name?.message}</span>
						</div>
						<div className="form-group">
							<label>{t('AMOUNT')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="amountOut" {...register('amountOut')} />
							<span className="text-danger text-sm">{errors.amountOut?.message}</span>
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2"
								onClick={() => closeModal()}>
								{t('CANCEL')}
							</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
