import React, { useEffect, useState, useRef } from "react"
import { Link, useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { Editor } from '@tinymce/tinymce-react';

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation'

import { GET, POST, PUT, FILE } from 'helpers/api'

function CreateUpdate() {
	const { t } = useTranslation();
	const { id } = useParams();
	const history = useHistory();

	const editorRef = useRef(null);

	const { register, handleSubmit, formState, control, setValue, getValues } = useForm({
		mode: "onChange",
		resolver: yupResolver(validationSchema),
	});
	const { errors } = formState

	const [poses, setPoses] = useState([])
	const [data, setData] = useState({
		'name': "",
		'description': "",
		'imageUrl': "",
		'posId': null,
	})

	async function createUpdate(validatedData) {
		var sendData = { ...validatedData }
		sendData.description = editorRef.current.getContent()
		sendData.imageUrl = data.imageUrl

		var response;
		if (id) {
			response = await PUT('/services/web/api/promotion', sendData, { loader: true })
			if (response) {
				history.push('/prize')
			}
		} else {
			response = await POST('/services/web/api/promotion', sendData, { loader: true })
			if (response) {
				history.push('/prize')
			}
		}
	}

	async function fileUpload(e) {
		let formData = new FormData();
		formData.append("image", e.target.files[0]);

		const response = await FILE('/services/web/api/upload/image', formData);
		setData({ ...data, 'imageUrl': response.url })
	}

	async function getData() {
		const response = await GET('/services/web/api/promotion/' + id)
		for (const [key, value] of Object.entries(response)) {
			setValue(key, value)
		}
		setData(response)
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		setPoses(response)
	}

	async function selectPos(option) {
		setValue('posId', option.id)
	}

	useEffect(() => {
		if (id) {
			getData()
		}
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<h4 className="vertical-center">
					{!id ?
						<span>{t('CREATE')}</span>
						:
						<span>{t('EDIT')}</span>
					}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="row">
						<div className="col-md-3">
							<div className="con-upload">
								<div className="con-img-upload">
									{data.imageUrl &&
										<div className="img-upload">
											<button type="button" className="btn-x-file" onClick={() => setData({ ...data, 'imageUrl': '' })}>
												<i className="uil-times"></i>
											</button>
											<img src={'https://cabinet.redeem.uz' + data.imageUrl}
												style={{ maxWidth: 'none', maxHeight: '100%' }} alt="react" />
										</div>
									}
									{!data.imageUrl &&
										<div className="con-input-upload">
											<input type="file" onChange={(e) => fileUpload(e)} />
											<span className="text-input fz14">
												{t('IMAGE2')}
											</span>
										</div>
									}
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="form-group">
								<label>{t('POS')}</label>
								<Controller
									name="posId"
									control={control}
									render={({ field }) =>
										<Select
											{...field}
											menuPosition="absolute"
											options={poses}
											value={poses.find(option => option.id === getValues('posId')) || ''}
											onChange={(option) => selectPos(option)}
											placeholder=""
											noOptionsMessage={() => t('LIST_IS_EMPTY')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>}
								/>
								<span className="text-danger text-sm">{errors.posId?.message}</span>
							</div>
							<div className="form-group">
								<label>{t('NAME3')}<span className="required-mark">*</span></label>
								<input type="text" className="form-control" name="name" {...register('name')} />
								<span className="text-danger text-sm">{errors.name?.message}</span>
							</div>
						</div>
					</div>

					<div className="row mt-5">
						<div className="col-md-12">
							<div className="form-group">
								<label>{t('DESCRIPTION')}</label>
								<Editor
									apiKey='0xe5b06q8q8cq3rwaxkhfgt2zi9y1ivbmjqz657m20zpiuz4'
									onInit={(evt, editor) => {
										editorRef.current = editor
									}}
									initialValue={data.description}
									init={{
										height: 300,
										menubar: false,
										plugins: [
											'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
											'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
											'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
										],
										toolbar: 'undo redo | blocks | ' +
											'bold italic forecolor | alignleft aligncenter ' +
											'alignright alignjustify | bullist numlist outdent indent | ' +
											'removeformat | help',
										content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
									}}
								/>
							</div>
						</div>
					</div>

					<div className="btn-wrapper">
						<Link type="button" className="btn btn-warning btn-rounded btn-wide me-2" to="/prize">{t('CANCEL')}</Link>
						<button type="button" className="btn btn-primary btn-rounded btn-wide btn-wide"
							onClick={handleSubmit(createUpdate)}>
							{t('SAVE')}
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default CreateUpdate