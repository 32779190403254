import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { PGET, POST } from 'helpers/api'
import { formatMoney, formatDate, returnSign, formatDateBackend } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const [data, setData] = useState([])
	const [notification, setNotification] = useState({
		'title': '',
		'message': '',
		'sendAll': false,
		'usersId': [],
	})
	const [showModal, setShowModal] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		amountFrom: null,
		amountTo: null,
		countChequeFrom: null,
		countChequeTo: null,
		startDate: null,
		endDate: null,
		search: ''
	})
	const [pagination, setPagination] = useState({
		url: '/services/web/api/clients-payment-pageList',
		response: null
	})

	async function sendNotification() {
		setShowModal(false)
		const response = await POST('/services/web/api/clients-info-send-notification', notification, {})
		if (response) {
			toast.success(t('SUCCESS'))
		}
	}

	function openModal(item) {
		var notificationCopy = { ...notification }
		if (item) {
			notificationCopy.sendAll = false
			notificationCopy.usersId = []
			notificationCopy.usersId.push(item.userId)
		} else {
			notificationCopy.sendAll = true
			notificationCopy.usersId = []
		}
		setNotification(notificationCopy)
		setShowModal(true)
	}

	async function searchWithFilter() {
		var filterUrl = "/services/web/api/clients-payment-pageList"
		var urlParams = ""
		checkFilter()

		if (filterData.amountFrom)
			urlParams += returnSign(urlParams) + 'amountFrom=' + filterData.amountFrom
		if (filterData.amountTo)
			urlParams += returnSign(urlParams) + 'amountTo=' + filterData.amountTo
		if (filterData.countChequeFrom)
			urlParams += returnSign(urlParams) + 'countChequeFrom=' + filterData.countChequeFrom
		if (filterData.countChequeTo)
			urlParams += returnSign(urlParams) + 'countChequeTo=' + filterData.countChequeTo
		if (filterData.startDate)
			urlParams += returnSign(urlParams) + 'startDate=' + filterData.startDate
		if (filterData.endDate)
			urlParams += returnSign(urlParams) + 'endDate=' + filterData.endDate
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if ("/services/web/api/clients-payment-pageList" + urlParams !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await PGET(filterUrl, {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response })
		}

		setDropdownIsOpen(false)
	}

	async function checkFilter() {
		if (
			filterData.amountFrom === null &&
			filterData.amountTo === null &&
			filterData.countChequeFrom === null &&
			filterData.countChequeTo === null &&
			filterData.startDate === null &&
			filterData.endDate === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function resetFilter() {
		setFilterData({
			amountFrom: null,
			amountTo: null,
			countChequeFrom: null,
			countChequeTo: null,
			startDate: null,
			endDate: null,
			search: ''
		})
		setFilterDataExist(false)
		getData()
	}

	async function getData() {
		const response = await PGET('/services/web/api/clients-payment-pageList', {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': '/services/web/api/clients-payment-pageList', 'response': response })
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(newPageData) {
		setData(newPageData)
	}

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('PURCHASES')}
				</h4>
				<button className="btn-rounded btn btn-primary" onClick={() => openModal()}>
					{t('SEND_PUSH_NOTIFICATION_TO_EVERYONE')}
				</button>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
						</div>
						<div>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('PAID')}</label>
											<input type="number" className="form-control" onChange={(e) => setFilterData({ ...filterData, 'amountFrom': e.target.value })} />
										</div>
										<div className="form-group col-md-6">
											<label className="invisible"></label>
											<input type="number" className="form-control" onChange={(e) => setFilterData({ ...filterData, 'amountTo': e.target.value })} />
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('NUMBER_OF_PURCHASES')}</label>
											<input type="number" className="form-control" onChange={(e) => setFilterData({ ...filterData, 'countChequeFrom': e.target.value })} />
										</div>
										<div className="form-group col-md-6">
											<label className="invisible"></label>
											<input type="number" className="form-control" onChange={(e) => setFilterData({ ...filterData, 'countChequeTo': e.target.value })} />
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('PERIOD')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.startDate ? new Date(filterData.startDate) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'startDate': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label className="invisible">.</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.endDate ? new Date(filterData.endDate) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'endDate': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('APPLY')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{(filterData.startDate || filterData.endDate) &&
									<span className="me-2">
										<b>{t('PERIOD')}: </b>
										{filterData.startDate &&
											<span>{formatDate(filterData.startDate)}</span>
										}
										{(filterData.startDate && filterData.endDate) &&
											<span>|</span>
										}
										{filterData.endDate &&
											<span>{formatDate(filterData.endDate)}</span>
										}
									</span>
								}
								{filterData.amountFrom &&
									<span className="me-2">
										<b>{t('PAID')}: </b> {filterData.amountFrom}
									</span>
								}
								{filterData.amountTo &&
									<span className="me-2">
										{filterData.amountTo}
									</span>
								}
								{filterData.countChequeFrom &&
									<span className="me-2">
										<b>{t('NUMBER_OF_PURCHASES')}: </b> {filterData.countChequeFrom}
									</span>
								}
								{filterData.countChequeTo &&
									<span className="me-2">
										{filterData.countChequeTo}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('POS')}</th>
									<th>{t('CLIENT')}</th>
									<th>{t('STATUS')}</th>
									<th className="text-center">{t('NUMBER_OF_PURCHASES')}</th>
									<th className="text-center">{t('AVERAGE_CHECK')}</th>
									<th className="text-center">{t('PAID')}</th>
									<th className="text-center">{t('PAID_WITH_BALLS')}</th>
									<th className="text-center">{t('BALANCE_OF_POINTS')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className="tr-middle" key={index}>
										<td>{item.posName}</td>
										<td>{item.clientName}</td>
										<td>{item.clientStatusName} ({item.award}%)</td>
										<td className="text-center text-nowrap">{formatMoney(item.countCheque)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.avgChequeAmount)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.cashPayment)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.writeOff)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.balance)}</td>
										<td>
											<div className="d-flex justify-content-center">
												<div className="table-action-button table-action-primary-button" onClick={() => openModal(item)}>
													<i className="uil-message"></i>
												</div>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{notification.sendAll ?
							<div>
								{t('SEND_PUSH_NOTIFICATION_TO_EVERYONE')}
							</div>
							:
							<div>
								{t('SEND_PUSH_NOTIFICATION')}
							</div>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={() => sendNotification()} autoComplete="off">
						<div className="form-group">
							<label>{t('NAME3')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" onChange={(e) => setNotification({ ...notification, 'title': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('DESCRIPTION')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" onChange={(e) => setNotification({ ...notification, 'message': e.target.value })} />
						</div>
						<p className="text-danger">
							{t('YOU_ARE_RESPONSIBLE')}
						</p>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => setShowModal(false)}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100"
								disabled={!notification.title || !notification.message}
							>{t('SEND')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
