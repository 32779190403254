import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import { Modal } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import { format, subDays } from "date-fns"

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { PGET, GET, PUT, POST } from 'helpers/api'
import { returnSign, formatDateBackend, formatDate, findFromArrayById } from 'helpers/helpers'


function Index() {
	const { t } = useTranslation()

	const [poses, setPoses] = useState([])
	const [gasColumns, setGasColumns] = useState([])
	const [data, setData] = useState([])
	const [selectedItem, setSelectedItem] = useState({})
	const [selectedItemCreate, setSelectedItemCreate] = useState({
		'counterDate': '',
		'counterList': []
	})
	const [showModal, setShowModal] = useState(false)
	const [showModal2, setShowModal2] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		posId: null,
		fromDate: null,
		toDate: null,
		search: ''
	})
	const [pagination, setPagination] = useState({
		url: '/services/web/api/gas-column-counter-pageList',
		response: null
	})

	async function update() {

		var selectedItemCopy = { ...selectedItem }

		var response = await PUT('/services/web/api/gas-column-counter', selectedItemCopy, { loader: true })

		if (response) {
			getData()
			setShowModal(false)
		}
	}

	async function create() {
		var selectedItemCreateCopy = { ...selectedItemCreate }
		var sendData = {
			'posId': selectedItemCreateCopy.posId,
			'counterDate': selectedItemCreateCopy.counterDate,
			'counterList': selectedItemCreateCopy.counterList
		}
		var response = await POST('/services/web/api/gas-column-counter', sendData, { loader: true })

		if (response) {
			searchWithFilter()
			setShowModal2(false)
		}
	}

	async function searchWithFilter() {
		var filterUrl = "/services/web/api/gas-column-counter-pageList"
		var urlParams = ""
		checkFilter()

		if (filterData.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterData.posId
		if (filterData.fromDate)
			urlParams += returnSign(urlParams) + 'fromDate=' + filterData.fromDate
		if (filterData.toDate)
			urlParams += returnSign(urlParams) + 'toDate=' + filterData.toDate
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if ("/services/web/api/gas-column-counter-pageList" + urlParams !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await PGET(filterUrl, {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response })
		}

		setDropdownIsOpen(false)
	}

	function openModal(item) {
		setSelectedItem(item)
		setShowModal(true)
	}

	function closeModal() {
		setSelectedItem({
			'counterDate': '',
			'counterList': []
		})
		setShowModal(false)
	}

	function openModal2() {
		setSelectedItemCreate({
			'counterDate': new Date(),
			'counterList': gasColumns,
		})
		setShowModal2(true)
	}

	function closeModal2() {
		setSelectedItemCreate({
			'counterDate': '',
			'counterList': [],
		})
		setShowModal2(false)
	}

	function resetFilter() {
		setFilterData({
			posId: null,
			fromDate: null,
			toDate: null,
			search: ''
		})
		setFilterDataExist(false)
		getData()
	}

	async function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.fromDate === null &&
			filterData.toDate === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getData() {
		const response = await PGET('/services/web/api/gas-column-counter-pageList', {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'response': response })
		}
	}

	async function handleSelectPos(option) {
		setFilterData({ ...filterData, 'posId': option.id })
		getGasColumns(option.id)
	}

	async function getGasColumns(id) {
		var response = await GET(`/services/web/api/gas-column-helper?posId=${id}`, {}, { loader: true })
		if (response) {
			setGasColumns(response)
		}
	}

	async function handleSelectPosCreate(option) {
		var response = await GET(`/services/web/api/gas-column-helper?posId=${option.id}`, {}, { loader: true })
		if (response) {
			setSelectedItemCreate({ ...selectedItemCreate, counterList: response, posId: option.id })
		}
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)
		}
	}

	function handleInputChange(value, index) {
		var selectedItemCopy = { ...selectedItemCreate }
		selectedItemCopy.counterList[index]['counter'] = value
		setSelectedItemCreate(selectedItemCopy)
	}

	function selectDate(date) {
		var yesterday = subDays(new Date(), 1)
		yesterday = format(new Date(yesterday), 'dd.MM.yyyy')
		const selectedDate = format(new Date(date), 'dd.MM.yyyy')

		if (yesterday === selectedDate) {
			setSelectedItemCreate({ ...selectedItemCreate, 'counterDate': formatDateBackend(date) })
		}
	}

	async function paginate(newPageData) {
		setData(newPageData)
	}

	useEffect(() => {
		//getData()
		//getGasColumns()
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('GAS_COLUMN_REPORT')}
				</h4>

				<div className="d-flex">
					<button className="btn-rounded btn btn-primary" onClick={() => openModal2(true)}>
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div>
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
						</div>
						<div>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('POSES')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.posId) || ''}
												onChange={(option) => handleSelectPos(option)}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
										<div className="form-group col-md-6">
											<label>{t('GAS_COLUMNS')}</label>
											<Select
												options={gasColumns}
												value={gasColumns.find(option => option.id === filterData.id) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'id': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('PERIOD')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.fromDate ? new Date(filterData.fromDate) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'fromDate': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label className="invisible">.</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.toDate ? new Date(filterData.toDate) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'toDate': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('APPLY')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{(filterData.fromDate || filterData.toDate) &&
									<span className="me-2">
										<b>{t('PERIOD')}: </b>
										{filterData.fromDate &&
											<span>{formatDate(filterData.fromDate)}</span>
										}
										{(filterData.fromDate && filterData.toDate) &&
											<span>|</span>
										}
										{filterData.toDate &&
											<span>{formatDate(filterData.toDate)}</span>
										}
									</span>
								}
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.id &&
									<span className="me-2">
										<b>{t('GAS_COLUMN2')}: </b> {findFromArrayById(gasColumns, filterData.id)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('NAME3')}</th>
									<th className="text-center">{t('INDEX')}</th>
									<th className="text-center">{t('LAST_INDEX')}</th>
									<th className="text-center">{t('DIFFERENCE')}</th>
									<th className="text-center">{t('DATE')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.length > 0 &&
									data.map((item, index) => (
										<tr key={index}>
											<td>{item.gasColumnName}</td>
											<td className="text-center">{item.counter}</td>
											<td className="text-center">{item.oldCounter}</td>
											<td className="text-center">{item.difference}</td>
											<td className="text-center">{formatDate(item.counterDate)}</td>
											<td className="d-flex justify-content-center">
												{item.changed &&
													<div className="table-action-button table-action-primary-button" onClick={() => openModal(item)}>
														<i className="uil-edit-alt"></i>
													</div>
												}
											</td>
										</tr>
									))
								}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered size="lg" backdrop="static" onHide={() => closeModal()}>
				<Modal.Body>
					<div className="table-responsive mb-3">
						<table className="table mb-0">
							<thead>
								<tr>
									<th>{t('NAME3')}</th>
									<th>{t('INDEX')}</th>
									<th className="text-center">{t('LAST_INDEX')}</th>
									<th className="text-center">{t('OUTCOME')}</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>{selectedItem.gasColumnName}</td>
									<td>
										<input type="text" className="auto-width-input"
											onChange={(e) => setSelectedItem({ ...selectedItem, 'counter': e.target.value })} />
									</td>
									<td className="text-center">{selectedItem.oldCounter}</td>
									<td className="text-center">{selectedItem.counter > 0 ? selectedItem.counter - selectedItem.oldCounter : '0'}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className="d-flex justify-content-end">
						<button className="btn btn-warning me-2" onClick={() => closeModal()}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary" onClick={() => update()}>{t('CONFIRM')}</button>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			{/* MODAL */}
			<Modal show={showModal2} animation={false} centered size="lg" backdrop="static" onHide={() => closeModal2()}>
				<Modal.Body>
					<div className="d-flex">
						<div className="form-group me-2">
							<label>{t('DATE')}<span className="required-mark">*</span></label>
							<div className="calendar-input">
								<DatePicker
									className="form-control"
									dateFormat="dd.MM.yyyy"
									selected={selectedItemCreate.counterDate ? new Date(selectedItemCreate.counterDate) : ''}
									onChange={(date) => selectDate(date)} />
								<i className="uil uil-calendar-alt"></i>
							</div>
						</div>
						<div className="form-group">
							<label>{t('POS')}<span className="required-mark">*</span></label>
							<div style={{ 'width': '207px' }}>
								<Select
									options={poses}
									value={poses.find(option => option.id === selectedItemCreate.posId) || ''}
									onChange={(option) => handleSelectPosCreate(option)}
									placeholder=""
									noOptionsMessage={() => t('LIST_IS_EMPTY')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>
						</div>
					</div>
					<div className="table-responsive mb-3">
						<table className="table mb-0">
							<thead>
								<tr>
									<th>{t('NAME3')}</th>
									<th>{t('INDICATION')}</th>
									<th className="text-center">{t('LAST_INDICATION')}</th>
									<th className="text-center">{t('TOTAL')}</th>
								</tr>
							</thead>
							<tbody>
								{selectedItemCreate.counterList.length > 0 &&
									selectedItemCreate.counterList.map((item, index) => (
										<tr key={index}>
											<td>{item.name}</td>
											<td>
												<input type="text" className="auto-width-input"
													onChange={(e) => handleInputChange(e.target.value, index)} />
											</td>
											<td className="text-center">{item.lastCounter}</td>
											<td className="text-center">
												{item.gasColumnType === 1 &&
													<span>{item.counter > 0 ? item.counter - item.lastCounter : ''}</span>
												}
												{item.gasColumnType === 2 &&
													<span>{item.counter > 0 ? item.counter : ''}</span>
												}
											</td>
										</tr>
									))
								}
							</tbody>
						</table>
					</div>
					<div className="d-flex justify-content-end">
						<button className="btn btn-warning me-2" onClick={() => closeModal2()}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary"
							disabled={!selectedItemCreate.posId}
							onClick={() => create()}>{t('SAVE')}</button>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

		</>
	)
}

export default Index
