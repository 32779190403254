import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './car_validation'

import { PGET, GET, PUT, POST, DELETE } from 'helpers/api'
import { formatMoney, regexPhoneNumber } from "helpers/helpers"
import ReactDatePicker from "react-datepicker"

function Index() {
	const { t } = useTranslation()

	const { register, handleSubmit, formState, control, setValue, getValues, reset } = useForm({
		mode: "onChange",
		resolver: yupResolver(validationSchema),
		defaultValues: {
			userId: "",
			carId: 0,
			phone: "",
			gender: "1",
		}
	});

	const { errors } = formState

	const [showModal, setShowModal] = useState(false)
	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const [showRegisterModal, setShowRegisterModal] = useState(false)
	const [isClientExist, setIsClientExist] = useState(true)

	const genders = [{ "id": 1, "name": t('MAN') }, { "id": 0, "name": t('WOMAN') }]
	const [data, setData] = useState([])
	const [selectedItem, setSelectedItem] = useState([])
	const [carsList, setCarList] = useState([{ carId: 0, carNumber: 'Создать новую машину' }])
	const [carTypes, setCarTypes] = useState([])
	const [client, setClient] = useState({
		phone: '',
		name: '',
		gender: '1',
		birthDate: new Date(),
	})

	async function createUpdate(validatedData) {
		var selectedItemCopy = { ...validatedData }
		selectedItemCopy.phone = '998' + selectedItemCopy.phone
		if (!selectedItemCopy.userId) {
			delete selectedItemCopy.userId
		}
		if (!selectedItemCopy.carId) {
			delete selectedItemCopy.carId
		}
		var response;
		if (selectedItemCopy.id) {
			response = await PUT('/services/web/api/my-company-cars', selectedItemCopy, { loader: true })
		} else {
			response = await POST('/services/web/api/my-company-cars', selectedItemCopy, { loader: true })
		}

		if (response) {
			getData()
			closeModal()
		}
	}

	async function deleteCar() {
		const response = await DELETE(`/services/web/api/my-company-cars/${selectedItem.carId}`, {}, { loader: true })
		if (response) {
			setShowConfirmModal(false)
			getData()
		}
	}

	async function editCar(item) {
		const response = await GET(`/services/web/api/my-company-cars/${item.carId}`, {}, { loader: true })
		searchUser(response['userPhone']?.substring(3, response['userPhone'].length))
		setSelectedItem(item)
		for (var key in response) {
			setValue(key, response[key])
		}
		setValue('phone', response['userPhone']?.substring(3, response['userPhone'].length))
		setShowModal(true)
	}

	function changeUserCar(option) {
		if (option.carId === 0) {
			for (var key in getValues()) {
				if (key !== 'carId' && key !== 'phone') {
					setValue(key, '')
				}
			}
			setValue('carId', option.carId)
			return;
		}
		setValue('carId', option.carId)
		setValue('carTypeId', option?.carTypeId)
		setValue('carNumber', option?.carNumber)
	}

	function registerModalUser() {
		setShowRegisterModal(true)
		setShowModal(false)
		setIsClientExist(true)
	}

	function closeRegisterModalUser() {
		for (var key in client) {
			setValue(key, client[key])
		}
		setShowRegisterModal(false)
		setShowModal(true)
	}

	function closeModal() {
		setShowModal(false)
		setCarList([{ carId: 0, carNumber: 'Создать новую машину' }])
		setValue('phone', '')
		reset()
	}

	async function searchUser(phone) {
		if (phone?.length === 9) {
			const response = await GET(`/services/web/api/users-cars-helper?phone=998${phone}`)
			if (response && response?.userId) {
				setValue('userId', response.userId)
				setCarList([{ carId: 0, carNumber: 'Создать новую машину' }, ...response.carsList])
				setIsClientExist(true)
				setShowModal(true)
				setShowRegisterModal(false)
			} else {
				setIsClientExist(false)
				setClient({ ...client, phone: phone })
			}
		} else {
			setValue('userId', '')
			setCarList([{ carId: 0, carNumber: 'Создать новую машину' }])
			setIsClientExist(true)
		}
	}

	async function getCartTypes() {
		const response = await GET('services/web/api/car-type-helper')
		if (response) {
			setCarTypes(response)
		}
	}

	async function getData() {
		const response = await PGET(`/services/web/api/my-company-cars`, {}, { loader: true })
		if (response) {
			setData(response.data)
		}
	}

	function checkPhone(e) {
		if (e.target.value > 9) {
			setValue(e.target.name, e.target.value.substring(0, 9))
		}
	}

	useEffect(() => {
		getData()
		getCartTypes()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('CARS')}
				</h4>
				<div className="d-flex">
					<div className="btn-rounded btn btn-primary" onClick={() => setShowModal(!showModal)}>
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</div>
				</div>
			</div>

			<div className="row mt-3">
				{
					data.map((item, index) => (
						<div className="col-md-4 mb-3" key={index}>
							<div className="card">
								<div className="card-body">
									<div className="d-flex align-items-center justify-content-between mb-2">
										<div>{t('CAR_NUMBER')}</div>
										<div><b>{item.carNumber}</b></div>
									</div>
									<div className="d-flex align-items-center justify-content-between mb-2">
										<div>{t('CAR_TYPE')}</div>
										<div><b>{item.carTypeName}</b></div>
									</div>
									<div className="d-flex align-items-center justify-content-between mb-2">
										<div>{t('USER')}</div>
										<div><b>{item.userName}</b></div>
									</div>
									<div className="d-flex align-items-center justify-content-between mb-2">
										<div>{t('PHONE')}</div>
										<div><b>{regexPhoneNumber(item.userPhone)}</b></div>
									</div>
									<div className="d-flex align-items-center justify-content-between mb-2">
										<div>{t('BALANCE')}</div>
										<div><b>{formatMoney(item.balance)}</b></div>
									</div>
									<div className="d-flex align-items-center justify-content-between mb-2">
										<div>{t('MONTH_LIMIT')}</div>
										<div><b>{formatMoney(item.monthLimit)}</b></div>
									</div>
									<div className="d-flex align-items-center justify-content-between mb-2">
										<div>{t('BALANCE_LIMIT')}</div>
										<div><b>{formatMoney(item.balanceLimit)}</b></div>
									</div>
									<div className="d-flex align-content-center justify-content-between">
										<div className="table-action-button table-action-danger-button w-100 p-3 me-xl-5 me-lg-3 me-2" onClick={() => {
											setSelectedItem(item);
											setShowConfirmModal(true)
										}}>
											{t('DELETE')}
										</div>
										<div className="table-action-button table-action-success-button w-100 p-3" onClick={() => editCar(item)}>
											{t('EDIT')}
										</div>
									</div>
								</div>
							</div>
						</div>
					))
				}
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedItem.id ?
							<>{t('EDIT')}</>
							:
							<>{t('CREATE')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(createUpdate)} autoComplete="off">
						<div className="form-group">
							<label>{t('PHONE')}<span className="required-mark">*</span></label>
							<div className="d-flex align-items-center justify-content-between w-100">
								<div className="input-group me-3">
									<span className="input-group-text">998</span>
									<input type="number" className="form-control" name="phone" {...register('phone', {
										onChange: (e) => {
											checkPhone(e)
											searchUser(e.target.value)
										},
									})} />
								</div>
								<button type="button" className="btn btn-primary w-50" onClick={() => searchUser(getValues('phone'))}
									disabled={getValues('phone').length !== 9}>
									{t('SEARCH')}
									<i className="uil uil-search ms-1"></i>
								</button>
							</div>
							<span className="text-danger text-sm">{errors.phone?.message}</span>
						</div>
						{
							!isClientExist &&
							<div onClick={() => registerModalUser()}>
								<button className="btn btn-primary w-100">{t('REGISTER')}</button>
							</div>
						}

						<div className="form-group">
							<label>{t('CARS')}<span className="required-mark">*</span></label>
							<Controller
								name="carId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										menuPosition="absolute"
										options={carsList}
										value={carsList.find(option => option.carId === getValues('carId')) || ''}
										onChange={(option) => changeUserCar(option)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.carNumber}
										getOptionValue={(option) => option.carId}
									/>}
							/>
							<span className="text-danger text-sm">{errors.carId?.message}</span>
						</div>
						<>
							<div className="form-group">
								<label>{t('CAR_TYPE')}<span className="required-mark">*</span></label>
								<Controller
									name="carTypeId"
									control={control}
									render={({ field }) =>
										<Select
											{...field}
											menuPosition="absolute"
											options={carTypes}
											value={carTypes.find(option => option.id === getValues('carTypeId')) || ''}
											onChange={(option) => setValue('carTypeId', option.id)}
											placeholder=""
											noOptionsMessage={() => t('LIST_IS_EMPTY')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>}
								/>
								<span className="text-danger text-sm">{errors.carTypeId?.message}</span>
							</div>
							<div className="form-group">
								<label>{t('CAR_NUMBER')}<span className="required-mark">*</span></label>
								<input type="text" className="form-control" name="carNumber" {...register('carNumber')} />
								<span className="text-danger text-sm">{errors.carNumber?.message}</span>
							</div>
							<div className="form-group">
								<label>{t('BALANCE')}<span className="required-mark">*</span></label>
								<input type="number" className="form-control" name="balance" {...register('balance')} />
								<span className="text-danger text-sm">{errors.balance?.message}</span>
							</div>
							<div className="form-group">
								<label>{t('MONTH_LIMIT')}<span className="required-mark">*</span></label>
								<input type="number" className="form-control" name="monthLimit" {...register('monthLimit')} />
								<span className="text-danger text-sm">{errors.monthLimit?.message}</span>
							</div>
							<div className="form-group">
								<label>{t('BALANCE_LIMIT')}<span className="required-mark">*</span></label>
								<input type="number" className="form-control" name="balanceLimit" {...register('balanceLimit')} disabled />
							</div>
						</>

						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2"
								onClick={() => closeModal()}>
								{t('CANCEL')}
							</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			{/* MODAL */}
			<Modal show={showRegisterModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setShowRegisterModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('REGISTER')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group">
						<label>{t('PHONE')}<span className="required-mark">*</span></label>
						<div className="input-group">
							<span className="input-group-text">998</span>
							<input type="number" className="form-control" value={client.phone} onChange={(e) => {
								if (e.target.value > 9) {
									setClient({ ...client, phone: e.target.value.substring(0, 9) })
									return
								}
								setClient({ ...client, phone: e.target.value })
							}} />
						</div>
					</div>
					<div className="form-group">
						<label>{t('NAME')}<span className="required-mark">*</span></label>
						<input type="text" className="form-control" value={client.name} onChange={(e) => setClient({ ...client, name: e.target.value })} />
					</div>
					<div className="form-group">
						<label>{t('GENDER')}<span className="required-mark">*</span></label>
						<Select
							menuPosition="absolute"
							options={genders}
							value={genders.find(option => option.id === client.gender) || ''}
							onChange={(option) => setClient({ ...client, gender: option.id })}
							placeholder=""
							noOptionsMessage={() => t('LIST_IS_EMPTY')}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
						/>
						<span className="text-danger text-sm">{errors.gender?.message}</span>
					</div>
					<div className="form-group">
						<label>{t('BIRTH_DATE')}<span className="required-mark">*</span></label>
						<ReactDatePicker
							className="form-control"
							selected={client.birthDate}
							dateFormat="dd.MM.yyyy"
							onChange={(date) => setClient({ ...client, 'birthDate': date })}
							peekNextMonth
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
						/>
					</div>
					<div className="d-flex w-100 mt-3">
						<button className="btn btn-outline-warning w-100 me-2"
							onClick={() => setShowRegisterModal(false)}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary w-100" onClick={() => closeRegisterModalUser()}>
							{t('SAVE')}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			{/* DELETE MODAL */}
			<Modal show={showConfirmModal} animation={false}
				centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setShowConfirmModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('DELETE')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<h5>{t('ARE_YOU_SURE_YOU_WANT_DELETE')}</h5>
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex w-100">
						<button className="btn btn-warning w-100 me-2" onClick={() => setShowConfirmModal(false)}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary w-100" onClick={() => deleteCar()}>{t('CONFIRM')}</button>
					</div>
				</Modal.Footer>
			</Modal>
			{/* DELETE MODAL */}
		</>
	)
}

export default Index
