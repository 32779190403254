import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import { PGET, PUT } from 'helpers/api'
import { formatMoney } from "helpers/helpers"

function Index() {
	const { t } = useTranslation()

	const URL = '/services/web/api/pos-pageList'

	const settings = useSelector(state => state.settings)
	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])

	async function activate(index) {
		var dataCopy = {...data}
		dataCopy[index]['open'] === true ? (dataCopy[index]['open'] = false) : (dataCopy[index]['open'] = true)
		const response = await PUT('/services/web/api/pos-open', dataCopy[index])
		if(response) {
			getData()
		}
	}

	async function getData() {
		const response = await PGET('/services/web/api/pos-pageList', {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	async function paginate(data) {
		const response = await PGET('/services/web/api/pos-pageList', { page: data.selected, size: settings.size }, { loader: true })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('POSES')}
				</h4>
				{/* <div className="d-flex">
					<Link className="btn-rounded btn btn-primary" to={'/pos-group/pos/create/' + id}>
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</Link>
				</div> */}
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('ID')}</th>
									<th>{t('IMAGE2')}</th>
									<th>{t('SALE_POINT_NAME')}</th>
									<th className="text-center">{t('OPEN')}</th>
									<th className="text-center">{t('ACTIVATED')}</th>
									<th className="text-center">{t('BALANCE')}</th>
									<th className="text-center">{t('TARIFF')}</th>
									<th className="text-center">{t('KEY')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className="tr-middle" key={index}>
										<td>{item.id}</td>
										<td>
											{item.imageUrl ?
												<img src={'https://cabinet.redeem.uz' + item.imageUrl} alt="react" width="50" height="50" />
												:
												<div className="table-no-image">
													<i className="uil uil-camera position-relative fz-20" style={{ 'top': '20%' }}></i>
												</div>
											}
										</td>
										<td>{item.name}</td>
										<td>
											<div className="d-flex justify-content-center">
												{item.open === true &&
													<span className="bg-success bg-border text-white">{t('YES')}</span>
												}
												{item.open === false &&
													<span className="bg-danger bg-border text-white text-nowrap">{t('NO')}</span>
												}
											</div>
										</td>
										<td className="text-center">
											<div className="d-flex justify-content-center">
												{item.activated === true &&
													<span className="bg-success bg-border text-white">{t('YES')}</span>
												}
												{item.activated === false &&
													<span className="bg-danger bg-border text-white text-nowrap">{t('NO')}</span>
												}
											</div>
										</td>
										<td className="text-center">{formatMoney(item.balance)}</td>
										<td className="text-center">{formatMoney(item.tariff)}</td>
										<td className="text-center">{item.apiKey}</td>
										<td>
											<div className="d-flex justify-content-center">
												{!item.open &&
													<div className="table-action-button table-action-success-button me-2" onClick={() => activate(index)}>
														<i className="uil-play"></i>
													</div>
												}
												{item.open &&
													<div className="table-action-button table-action-danger-button me-2" onClick={() => activate(index)}>
														<i className="uil-stop-circle"></i>
													</div>
												}
												<Link className="table-action-button table-action-success-button"
													to={'/pos/update/' + item.id}>
													<i className="uil-edit-alt"></i>
												</Link>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Index
