import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import { PGET, GET } from 'helpers/api'
import { formatDate, formatMoney, regexPhoneNumber } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const [poses, setPoses] = useState([])
	const [posId, setPosId] = useState(0)
	const [data, setData] = useState([])
	const [pagination] = useState({
		url: '/services/redeemapi/api/user-pageList',
		response: null
	})
	const [pageCount, setPageCount] = useState(0)

	async function paginate(data) {
		const response = await PGET(pagination.url, { page: data.selected, size: 20 })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	async function getData(posId) {
		var filterUrl = ''
		if (posId) {
			filterUrl = `?posId=${posId}`
		}
		const response = await PGET(`/services/web/api/user-debt-pageList${filterUrl}`, {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.total / 20))
			setData(response.data)
		}
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)
			getData(response[0]['id'])
			setPosId(response[0]['id'])
		}
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('DEBTORS')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div>
							<Search url={pagination.url} search={search} getData={getData}></Search>
						</div>
						<div style={{ 'width': '207px' }}>
							<Select
								options={poses}
								value={poses.find(option => option.id === posId) || ''}
								onChange={(option) => getData(option.id)}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
					</div>

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>№ {t('NAME')}</th>
									<th>{t('PHONE')}</th>
									<th>{t('CAR_NUMBER')}</th>
									<th>{t('CAR_TYPE')}</th>
									<th className="text-center">{t('BALANCE')}</th>
									<th className="text-center">{t('DEBT')}</th>
									<th className="text-center">{t('RETURN_DATE')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>
											<Link className="text-primary cursor table-first-column-clickable"
												to={`/debtors/${posId}/${item.userId}`}>
												{item.rowNum}.
												{item.name}
											</Link>
										</td>
										<td>{regexPhoneNumber(item.login)}</td>
										<td>{item.carNumber}</td>
										<td>{item.carTypeName}</td>
										<td className="text-center">{formatMoney(item.balance)}</td>
										<td className="text-center">{formatMoney(item.debtAmount)}</td>
										<td className="text-center">{formatDate(item.debtReturnDate)}</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Index
