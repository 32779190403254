import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { toast } from "react-toastify"

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import { PGET, PUT, POST } from 'helpers/api'
import { formatDate, formatMoney, regexPhoneNumber } from "helpers/helpers"

function Index() {
	const { t } = useTranslation()

	const URL = '/services/web/api/company-pageList'

	const settings = useSelector(state => state.settings)
	const [modalPayment, setModalPayment] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])

	async function payment(e) {
		e.preventDefault();

		var selectedItemCopy = { ...selectedItem }
		var sendData = {}
		sendData.amountIn = selectedItemCopy.amountIn
		sendData.companyId = selectedItemCopy.companyId
		sendData.note = selectedItemCopy.note

		var response = await POST('/services/web/api/company-payment', sendData, { loader: true })
		if (response) {
			showPaymentModal(false)
			getData()
		}
	}

	async function showPaymentModal(bool) {
		if (!bool) {
		} else {
			if (!selectedItem?.id) {
				toast.warning(t('NO_COMPANY_SELECTED'))
				return
			}
		}
		setModalPayment(bool)
	}

	function selectItem(item) {
		var itemCopy = { ...item }
		itemCopy.companyId = itemCopy.id
		itemCopy.amountIn = ''
		setSelectedItem(itemCopy)
	}

	async function activate(index) {
		var dataCopy = { ...data }
		dataCopy[index]['activated'] === true ? (dataCopy[index]['activated'] = false) : (dataCopy[index]['activated'] = true)
		const response = await PUT('/services/web/api/company-activate', dataCopy[index])
		if (response) {
			getData()
		}
	}

	async function getData() {
		const response = await PGET('/services/web/api/company-pageList', {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	async function paginate(data) {
		const response = await PGET('/services/web/api/company-pageList', { page: data.selected, size: settings.size }, { loader: true })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('COMPANY')}
				</h4>
				<div className="d-flex">
					<Link className="btn-rounded btn btn-primary" to={'/company/create'}>
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</Link>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
						<div>
							<button className="btn btn-outline-primary btn-wide me-2" onClick={() => showPaymentModal(true)}>
								{t('PAY')}
							</button>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-hover table-striped mb-0">
							<thead>
								<tr>
									<th>{t('COMPANY_NAME')}</th>
									<th>{t('PHONE')}</th>
									<th className="text-center">{t('TIN')}</th>
									<th className="text-center">{t('BALANCE')}</th>
									<th className="text-center">{t('CREATED_AT')}</th>
									<th className="text-center">{t('ACTIVATE')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className={'tr-middle ' + (
										(selectedItem && selectedItem.companyId === item.id) ? 'table-tr-active' : '')}
										key={index} onClick={() => selectItem(item)}>
										<td>{item.name}</td>
										<td>{regexPhoneNumber(item.phone)}</td>
										<td className="text-center">{item.tin}</td>
										<td className="text-center">{formatMoney(item.balance)}</td>
										<td className="text-center">{formatDate(item.createdDate)}</td>
										<td>
											<div className="form-check form-switch form-switch-lg d-flex justify-content-center">
												<input type="checkbox" className="form-check-input" name="bankPrice" onChange={() => activate(index)}
													checked={item.activated} />
											</div>
										</td>
										<td>
											<div className="d-flex justify-content-center">
												<Link className="table-action-button table-action-success-button me-2"
													title={t('EDIT')} to={'/company/update/' + item.id}>
													<i className="uil-edit-alt"></i>
												</Link>
												<Link className="table-action-button table-action-success-button"
													title={t('CARS')} to={'/company/cars/' + item.id}>
													<i className="uil-car-sideview"></i>
												</Link>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			{/* MODAL PAYMENT */}
			<Modal show={modalPayment} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => showPaymentModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('PAY')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={payment} autoComplete="off">
						<div className="form-group">
							<label>{t('AMOUNT')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="amountIn" autoFocus step="any"
								value={selectedItem.amountIn} onChange={(e) => {
									if (Number(e.target.value) >= 1000000000) {
										setSelectedItem({ ...selectedItem, 'amountIn': e.target.value.substring(0, e.target.value.length - 1) })
										return;
									}
									if (Number(e.target.value) <= 0) {
										setSelectedItem({ ...selectedItem, 'amountIn': '' })
										return;
									}
									setSelectedItem({ ...selectedItem, 'amountIn': e.target.value })
								}}
							/>
						</div>
						<div className="form-group">
							<label>{t('NOTE')}</label>
							<input type="text" className="form-control" name="note"
								onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2"
								onClick={() => showPaymentModal(false)} tabIndex="-1">{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100" disabled={!selectedItem.amountIn}>
								{t('SAVE')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL PAYMENT */}
		</>
	)
}

export default Index
