import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactApexChart from "react-apexcharts";
import DatePicker from "react-datepicker"
import Select from 'react-select'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { GET } from 'helpers/api'
import { formatMoney, formatDateBackend, returnSign } from 'helpers/helpers';

function Index() {
	const { t } = useTranslation()


	const [topData, setTopData] = useState([
		{
			'name': 'TOTAL_CLIENTS',
			'data': 0,
			'icon': 'uil-users-alt',
			'bg-icon': 'bg-success',
		},
		{
			'name': 'MAN',
			'data': 0,
			'icon': 'uil-mars',
			'bg-icon': 'bg-success',
		},
		{
			'name': 'WOMAN',
			'data': 0,
			'icon': 'uil-venus',
			'bg-icon': 'bg-success',
		},
		{
			'name': 'MADE_PURCHASES',
			'data': 0,
			'icon': 'uil-transaction',
			'bg-icon': 'bg-success',
		},
		{
			'name': 'PAID_IN_SUMS',
			'data': 0,
			'icon': 'uil-usd-circle',
			'bg-icon': 'bg-success',
		},
		{
			'name': 'PAID_WITH_BALLS',
			'data': 0,
			'icon': 'uil-usd-square',
			'bg-icon': 'bg-success',
		},
		{
			'name': 'CUSTOMER_POINTS',
			'data': 0,
			'icon': 'uil-award',
			'bg-icon': 'bg-success',
		},
		{
			'name': 'AVERAGE_CHECK',
			'data': 0,
			'icon': 'uil-invoice',
			//uil-wallet
			'bg-icon': 'bg-success',
		},
		{
			'name': 'AVERAGE_AGE',
			'data': 0,
			'icon': 'uil-user-square',
			'bg-icon': 'bg-success',
		},
	])
	const [graphData, setGraphData] = useState({
		series: [{
			name: t('PURCHASE_AMOUNT'),
			data: []
		}, {
			name: t('AMOUNT_OF_BONUSES_ACCRUED'),
			data: []
		},
		{
			name: t('BONUSES_WRITTEN_OFF'),
			data: []
		}],
		options: {
			colors: ['#00AF50', '#f1b44c', '#50a5f1'],
			chart: {
				height: 350,
				type: 'area',
				toolbar: {
					show: false
				},
			},
			legend: {
				show: false
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth'
			},
			yaxis: {
				labels: {
					formatter: function (value) {
						return formatMoney(value, 0);
					}
				},
			},
			xaxis: {
				type: 'datetime',
				labels: {
					format: 'dd.MM',
				},
				tooltip: {
					enabled: false
				},
				categories: []
			},
			tooltip: {
				x: {
					format: 'dd.MM.yyyy'
				},
			},
		},
	})
	const [graphTotal, setGraphTotal] = useState({
		'amountPurchases': 0,
		'amountBonuses': 0,
		'amountWriteOff': 0
	})
	const [show] = useState(!false)
	const [loader, setLoader] = useState(true)
	const [poses, setPoses] = useState([])
	const [filterData, setFilterData] = useState({
		'startDate': null,
		'endDate': null,
		'posId': null
	})


	async function getData() {
		setLoader(true)
		var urlParams = ""
		if (filterData.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterData.posId
		if (filterData.startDate)
			urlParams += returnSign(urlParams) + 'startDate=' + filterData.startDate
		if (filterData.endDate)
			urlParams += returnSign(urlParams) + 'endDate=' + filterData.endDate

		const response = await GET('/services/web/api/main-report' + urlParams)
		if (response) {
			var graphDataCopy = { ...graphData }
			
			for (let i = 0; i < response['amountByDateList'].length; i++) {
				graphDataCopy.series[0]['data'].push(response['amountByDateList'][i]['purchases'])
				graphDataCopy.series[1]['data'].push(response['amountByDateList'][i]['bonuses'])
				graphDataCopy.series[2]['data'].push(response['amountByDateList'][i]['writeOff'])

				graphDataCopy.options.xaxis.categories.push(response['amountByDateList'][i]['chequeDate'])
			}
			
			var topDataCopy = [...topData]
			topDataCopy[0]['data'] = response.countUsers == null ? 0 : response.countUsers
			topDataCopy[1]['data'] = response.countMen == null ? 0 : response.countMen
			topDataCopy[2]['data'] = response.countWomen == null ? 0 : response.countWomen
			topDataCopy[3]['data'] = response.totalAmount == null ? 0 : response.totalAmount
			topDataCopy[4]['data'] = response.payment == null ? 0 : response.payment
			topDataCopy[5]['data'] = response.writeOff == null ? 0 : response.writeOff
			topDataCopy[6]['data'] = response.balanceUsers == null ? 0 : response.balanceUsers
			topDataCopy[7]['data'] = response.avgChequeAmount == null ? 0 : response.avgChequeAmount
			topDataCopy[8]['data'] = response.avgAge == null ? 0 : response.avgAge
			setGraphData(graphDataCopy)
			setTopData(topDataCopy)
			setGraphTotal({
				...graphTotal,
				'amountPurchases': response.totalAmount,
				'amountBonuses': response.bonuses,
				'amountWriteOff': response.writeOff
			})
			setLoader(false)
		}
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)
		}
	}

	useEffect(() => {
		getData()
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('STATISTICS')}
				</h4>
				<div className="d-flex flex-wrap justify-content-end">
					<div className="w-25 me-2">
						<Select
							options={poses}
							value={poses.find(option => option.id === filterData.posId) || ''}
							onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
							placeholder=""
							noOptionsMessage={() => t('LIST_IS_EMPTY')}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
						/>
					</div>
					<div className="w-25 me-2">
						<div className="calendar-input">
							<DatePicker
								className="form-control"
								dateFormat="dd.MM.yyyy"
								selected={filterData.startDate ? new Date(filterData.startDate) : ''}
								onChange={(date) => setFilterData({ ...filterData, 'startDate': formatDateBackend(date) })} />
							<i className="uil uil-calendar-alt"></i>
						</div>
					</div>
					<div className="w-25 me-2">
						<div className="calendar-input">
							<DatePicker
								className="form-control"
								dateFormat="dd.MM.yyyy"
								selected={filterData.endDate ? new Date(filterData.endDate) : ''}
								onChange={(date) => setFilterData({ ...filterData, 'endDate': formatDateBackend(date) })} />
							<i className="uil uil-calendar-alt"></i>
						</div>
					</div>
					<div>
						<button className="btn btn-primary" onClick={() => getData()}>
							{t('FILTER')}
						</button>
					</div>
				</div>
			</div>

			{show &&
				<>
					{!loader &&
						<>
							<div className="row mb-3">
								{topData.map((item, index) => (
									<div className="col-md-4 mb-3" key={index}>
										<div className="card h-100">
											<div className="card-body">
												<div className="d-flex justify-content-between">
													<div className="mt-2">
														<div className="rounded-circle iq-card-icon bg-primary">
															<i className={item.icon}></i>
														</div>
													</div>
													<div className="mt-1">
														<h4 className="d-flex justify-content-end"><b>{formatMoney(item.data)}</b></h4>
														<p className="d-flex justify-content-end text-muted mb-0">{t(item.name)}</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								))
								}
							</div>

							<div className="row">
								<div className="col-md-12">
									<div className="card">
										<div className="card-body">
											<div className="d-flex justify-content-between">
												<h4>{t('STATISTICS')}</h4>
											</div>
											<div className="d-flex">
												<div className="d-flex flex-column justify-content-between me-5">
													<strong className="fz14">{t('PURCHASE_AMOUNT')}</strong>
													<div className="position-relative">
														<span className="fz24 text-success">{formatMoney(graphTotal.amountPurchases)}</span>
														<span className="text-success position-absolute">{t('SUM')}</span>
													</div>
												</div>
												<div className="d-flex flex-column justify-content-between me-5">
													<strong className="fz14">{t('AMOUNT_OF_BONUSES_ACCRUED')}</strong>
													<div className="position-relative">
														<span className="fz24 text-warning">{formatMoney(graphTotal.amountBonuses)}</span>
														<span className="text-warning position-absolute">{t('POINTS')}</span>
													</div>
												</div>
												<div className="d-flex flex-column justify-content-between">
													<strong className="fz14">{t('BONUSES_WRITTEN_OFF')}</strong>
													<div className="position-relative">
														<span className="fz24 text-info">{formatMoney(graphTotal.amountWriteOff)}</span>
														<span className="text-info position-absolute">{t('POINTS')}</span>
													</div>
												</div>
											</div>
											<hr />
											<ReactApexChart options={graphData.options} series={graphData.series} type="area" height={300} />
										</div>
									</div>
								</div>
							</div>
						</>
					}
					{loader &&
						<>
							<div className="row mb-3">
								<div className="col-md-3">
									<Skeleton height={90.2} />
								</div>
								<div className="col-md-3">
									<Skeleton height={90.2} />
								</div>
								<div className="col-md-3">
									<Skeleton height={90.2} />
								</div>
								<div className="col-md-3">
									<Skeleton height={90.2} />
								</div>
							</div>

							<div className="row">
								<div className="col-md-12">
									<Skeleton height={300} />
								</div>
							</div>
						</>
					}
				</>
			}
		</>
	)
}

export default Index
