import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { GET, POST, PUT, PGET } from 'helpers/api'
import { formatMoney, returnSign, findFromArrayById, formatDateBackend, formatDate } from "helpers/helpers"

function Index() {
	const { t } = useTranslation()

	const { register, handleSubmit, formState, setValue, getValues, control } = useForm({
		mode: "onChange",
		resolver: yupResolver(validationSchema),
		defaultValues: {'transferAmount': 0}
	});
	const { errors } = formState

	const [poses, setPoses] = useState([])
	const [data, setData] = useState([])
	const [total, setTotal] = useState({})
	const [selectedItem, setSelectedItem] = useState({})
	const [showModal, setShowModal] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		posId: null,
		ageFrom: null,
		ageTo: null,
		gender: null,
		search: ''
	})
	const [pagination, setPagination] = useState({
		url: '/services/web/api/shift-pageList',
		response: null
	})

	async function createUpdate(validatedData) {

		var sendData = { ...validatedData }

		var response;
		if (sendData.id) {
			response = await PUT('/services/web/api/shift', sendData, { loader: true })
		} else {
			response = await POST('/services/web/api/shift', sendData, { loader: true })
		}

		if (response) {
			getData()
			setShowModal(false)
			setSelectedItem({})
		}
	}

	function openModal() {
		setValue('posId', poses[0]['id'])
		setShowModal(true)
	}

	function closeModal() {
		setShowModal(false)
	}

	async function searchWithFilter() {
		var filterUrl = "/services/web/api/shift-pageList"
		var urlParams = ""
		checkFilter()

		if (filterData.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterData.posId
		if (filterData.shiftDate)
			urlParams += returnSign(urlParams) + 'shiftDate=' + filterData.shiftDate
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if ("/services/web/api/shift-pageList" + urlParams !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await PGET(filterUrl, {}, { loader: true })
		const responseTotal = await GET("/services/web/api/shift-total" + urlParams, {}, { loader: true })
		if (response) {
			setData(response.data)
			setTotal(responseTotal)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response })
		}

		setDropdownIsOpen(false)
	}

	async function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.shiftDate === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function resetFilter() {
		setFilterData({
			posId: null,
			shiftDate: null,
			search: ''
		})
		setFilterDataExist(false)
		getData()
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(newPageData) {
		setData(newPageData)
	}

	async function handleDateChange(date) {
		setValue('shiftDate', formatDateBackend(date))
		const response = await GET('/services/web/api/expenses-date', {
			posId: getValues('posId'),
			shiftDate: getValues('shiftDate'),
		}, { loader: true })
		setValue('otherAmount', response)
	}

	async function getData() {
		const response = await PGET('/services/web/api/shift-pageList', {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': '/services/web/api/shift-pageList', 'response': response })
		}
	}

	async function getTotal() {
		const response = await GET('/services/web/api/shift-total', {}, { loader: true })
		if (response) {
			setTotal(response)
		}
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)
		}
	}

	useEffect(() => {
		getTotal()
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('SHIFTS')}
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary" onClick={() => {
						setSelectedItem({})
						openModal()
					}}>
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex justify-content-between mb-3">
						<div className="d-flex">
							<div className="me-2">
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('POSES')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.posId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('DATE')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.shiftDate ? new Date(filterData.shiftDate) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'shiftDate': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter()}>{t('APPLY')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>

						<div className="vertical-center">
							<h5 className="text-end">
								<b className="me-3">{t('RESIDUE')}:</b>{formatMoney(total?.totalAmount)} <br />
								<b className="me-3">{t('EXPENSE')}:</b>{formatMoney(total?.otherAmount)}
							</h5>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
								{filterData.shiftDate &&
									<span className="me-2">
										<b>{t('DATE')}: </b>
										<span>{filterData.shiftDate}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('DATE')}</th>
									<th className="text-center">{t('COLUMN')} </th>
									<th className="text-center">{t('AUTOPILOT')}</th>
									<th className="text-center">{t('LOSS')}</th>
									<th className="text-center">{t('IMPLEMENTATION')}</th>
									<th className="text-center">{t('COLUMN_SUM')}</th>
									<th className="text-center">{t('AUTOPILOT_SUMMA')}</th>
									<th className="text-center">{t('FACT')}</th>
									<th className="text-center">{t('CASH')}</th>
									<th className="text-center">{t('TERMINAL')}</th>
									<th className="text-center">{t('MONEY_TRANSFER')}</th>
									<th className="text-center">{t('ALL2')}</th>
									<th className="text-center">{t('DEBT')}</th>
									<th className="text-center">{t('EXPENSE')}</th>
									<th className="text-center">{t('REMAINDER')}</th>
									<th className="text-center">{t('BONUS')}</th>
									<th className="text-center">{t('PAID_WITH_BALLS')}</th>
								</tr>
							</thead>
							<tbody>
								{data.length > 0 &&
									data.map((item, index) => (
										<tr key={index}>
											<td className="text-nowrap">{item.rowNum}. {formatDate(item.shiftDate)}</td>
											<td className="text-center text-nowrap">{item.gasColumn}</td>
											<td className="text-center text-nowrap">{formatMoney(item.autopilot)}</td>
											<td className="text-center text-nowrap">{formatMoney(item.loss)}</td>
											<td className="text-center text-nowrap text-warning"><b>{formatMoney(item.realization)}</b></td>
											<td className="text-center text-nowrap text-danger"><b>{formatMoney(item.gasColumnAmount)}</b></td>
											<td className="text-center text-nowrap text-success"><b>{formatMoney(item.autopilotAmount)}</b></td>
											<td className="text-center text-nowrap">{formatMoney(item.factAmount)}</td>
											<td className="text-center text-nowrap text-success"><b>{formatMoney(item.cashAmount)}</b></td>
											<td className="text-center text-nowrap">{formatMoney(item.cardAmount)}</td>
											<td className="text-center text-nowrap text-danger"><b>{formatMoney(item.transferAmount)}</b></td>
											<td className="text-center text-nowrap">{formatMoney(item.totalAmount)}</td>
											<td className="text-center text-nowrap">{formatMoney(item.debtAmount)}</td>
											<td className="text-center text-nowrap">{formatMoney(item.otherAmount)}</td>
											<td className="text-center text-nowrap text-warning"><b>{formatMoney(item.balance)}</b></td>
											<td className="text-center text-nowrap text-danger"><b>{formatMoney(item.bonusAmount)}</b></td>
											<td className="text-center text-nowrap text-success"><b>{formatMoney(item.writeOffAmount)}</b></td>
										</tr>
									))
								}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedItem.id ?
							<>{t('EDIT')}</>
							:
							<>{t('CREATE')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(createUpdate)} autoComplete="off">
						<div className="form-group">
							<label>{t('POS')}</label>
							<Controller
								name="posId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										menuPosition="absolute"
										options={poses}
										value={poses.find(option => option.id === getValues('posId')) || ''}
										onChange={(option) => setValue('posId', option.id)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>}
							/>
							<span className="text-danger text-sm">{errors.posId?.message}</span>
						</div>
						<div className="form-group">
							<label>{t('DATE')}<span className="required-mark">*</span></label>
							<div className="calendar-input">
								<Controller
									name="shiftDate"
									control={control}
									render={({ field }) =>
										<DatePicker
											{...field}
											className="form-control"
											dateFormat="dd.MM.yyyy"
											selected={getValues('shiftDate') ? new Date(getValues('shiftDate')) : ''}
											onChange={(date) => handleDateChange(date)}
										/>}
								/>
								<span className="text-danger text-sm">{errors.shiftDate?.message}</span>
								<i className="uil uil-calendar-alt"></i>
							</div>
						</div>
						<div className="form-group">
							<label>{t('EXPENSE')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="otherAmount" {...register('otherAmount')} />
							<span className="text-danger text-sm">{errors.otherAmount?.message}</span>
						</div>
						<div className="form-group">
							<label>{t('AUTOPILOT')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="autopilot" {...register('autopilot')} />
							<span className="text-danger text-sm">{errors.autopilot?.message}</span>
						</div>
						<div className="form-group">
							<label>{t('TERMINAL')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="cardAmount" {...register('cardAmount')} />
							<span className="text-danger text-sm">{errors.cardAmount?.message}</span>
						</div>
						<div className="form-group">
							<label>{t('MONEY_TRANSFER')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="transferAmount" {...register('transferAmount')} />
							<span className="text-danger text-sm">{errors.transferAmount?.message}</span>
						</div>
						<div className="form-group">
							<label>{t('DEBT')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="debtAmount" {...register('debtAmount')} />
							<span className="text-danger text-sm">{errors.debtAmount?.message}</span>
						</div>

						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
