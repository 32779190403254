import React, { useState, useEffect, useRef } from "react"
import { useTranslation } from 'react-i18next'
import DatePicker from "react-datepicker"
//import { DebounceInput } from 'react-debounce-input'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'

import { useForm, Controller } from "react-hook-form";

import { toast } from 'react-toastify'
import { GET, POST, PUT } from "helpers/api";
import { formatDate, formatDateBackend, formatDateWithTime, formatMoney, formatMoneyInput, regexPhoneNumber } from "helpers/helpers";

function Index() {
	const { t } = useTranslation()

	const clientCodeRef = useRef(null)
	const clientCarNumberRef = useRef(null)
	const paymentRef = useRef(null)
	const writeOffRef = useRef(null)
	const debtRef = useRef(null)

	const { register, handleSubmit, formState, control, reset, setValue, getValues } = useForm({
		mode: "onChange",
	});
	const { errors } = formState

	const [activeTab, setActiveTab] = useState(1)
	const [showModal, setShowModal] = useState(false)
	const [carTypes, setCarTypes] = useState([])
	const [data, setData] = useState({
		"posId": localStorage.getItem('pos_id'),
		"clientCode": "",
		"totalAmount": "",
		"writeOff": "",
		"carId": ""
	});
	const [writeOffData, setWriteOffData] = useState({
		"posId": localStorage.getItem('pos_id'),
		"clientCode": "",
		"writeOff": ""
	});
	const [debtData, setDebtData] = useState({
		"posId": localStorage.getItem('pos_id'),
		"clientCode": "",
		"debt": ""
	});
	const [client, setClient] = useState({
		'chequeList': [{}, {}, {}, {}, {}]
	});

	async function createCheque(chequeType) {
		if (validateButton() && !client.paid) {
			return
		}
		var dataCopy = { ...data }
		dataCopy.totalAmount = dataCopy.totalAmount?.replace(/ /g, '')

		if (client.carCompany && Number(client.monthLimit) < dataCopy.totalAmount) {
			toast.dismiss()
			toast.error(t('LIMIT_EXCEEDED'))
			return
		}

		if (client?.carTypeAmount && Number(dataCopy.totalAmount) > client.carTypeAmount) {
			toast.dismiss()
			toast.error(t('LIMIT_EXCEEDED'))
			return
		}

		dataCopy.bonus = (dataCopy.totalAmount - data.writeOff) * client.award / 100

		var sendData = {
			"posId": data.posId,
			"clientCode": data.clientCode,
			"carId": data.carId ? data.carId : '',
			"carCompany": data.carCompany,
			"totalAmount": dataCopy.totalAmount,
			"writeOff": dataCopy.writeOff ? dataCopy.writeOff : 0,
			"bonus": dataCopy.bonus ? dataCopy.bonus : 0,
			"chequeType": chequeType === 1 ? 1 : 2
		}

		const response = await POST(`/services/redeemapi/api/cashbox/cheque`, sendData, { loader: true })
		if (response) {
			setData({
				"posId": localStorage.getItem('pos_id'),
				"clientCode": "",
				"totalAmount": "",
				"writeOff": "",
				"carNumberSearch": "",
			})
			setClient({
				'chequeList': [{}, {}, {}, {}, {}]
			})
			clientCodeRef.current.select()
		}
	}

	async function createWriteOff() {
		var writeOffDataCopy = { ...writeOffData }
		var sendData = {
			"posId": writeOffData.posId,
			"clientCode": writeOffData.clientCode,
			"writeOff": writeOffDataCopy.writeOff ? writeOffDataCopy.writeOff : 0
		}

		const response = await POST(`/services/redeemapi/api/cashbox/write-off`, sendData, { loader: true })
		if (response) {
			setWriteOffData({
				"posId": localStorage.getItem('pos_id'),
				"clientCode": "",
				"writeOff": ""
			})
			setClient({
				'chequeList': [{}, {}, {}, {}, {}]
			})
			clientCodeRef.current.select()
		}
	}

	async function createDebt(chequeType) {
		var debtDataCopy = { ...debtData }
		var sendData = {
			"posId": debtDataCopy.posId,
			"clientCode": debtDataCopy.clientCode,
			"returnDate": debtDataCopy.returnDate,
			"debt": debtDataCopy.debt ? debtDataCopy.debt : 0,
			"chequeType": chequeType === 1 ? 1 : 2
		}

		const response = await POST(`/services/redeemapi/api/cashbox/cheque-debt`, sendData, { loader: true })
		if (response) {
			setDebtData({
				"posId": localStorage.getItem('pos_id'),
				"clientCode": "",
				"debt": ""
			})
			setClient({
				'chequeList': [{}, {}, {}, {}, {}]
			})
			clientCodeRef.current.select()
		}
	}

	function updateCar(item) {
		setData({ ...data, 'carId': item?.carId, 'carCompany': item?.carCompany, writeOff: '' })
		setClient({
			...client,
			...item
		})
	}

	async function requestToServer(value, type = "", isMy = false) {
		var url = `/services/redeemapi/api/user-balance/${data.posId}/${value}`
		if (type) {
			url = `/services/redeemapi/api/my-user-balance/${data.posId}/${value}`
		}
		setData({ ...data, 'clientCode': value })
		const response = await GET(url, true, {
			loader: true
		})
		if (response.success) {
			// setClient(response.user)
			if (response?.user?.carsList) {
				setData({ ...data, 'carId': response?.user?.carsList[0]?.carId, 'carCompany': response?.user?.carsList[0]?.carCompany })
				if (response.user.carsList[0]?.balance != null && response.user.carsList[0]?.balance !== undefined) {
					response.user.carsList[0].balance = response.user.balance
				}
				setClient({
					...response.user,
					...response?.user?.carsList[0]
				})
			}
			response.user.carNumberBool = false
			response.user.carTypeBool = false
			if (type === "writeOff") {
				writeOffRef.current.select()
				return
			}
			if (type === "debt") {
				debtRef.current.select()
				return
			}
			paymentRef.current.select()
		}
	}

	async function requestToServerCar(value) {
		setData({ ...data, 'carNumberSearch': value })
		const response = await GET(`/services/redeemapi/api/user-balance-car/${data.posId}?carNumber=${value}`, true, {
			loader: true
		})
		if (response.success) {
			setClient(response.user)
			response.user.carNumberBool = false
			response.user.carTypeBool = false
			setData({ ...data, 'clientCode': response?.user?.login, 'carId': response?.user?.carsList[0]?.carId, 'carCompany': response?.user?.carsList[0]?.carCompany })
			paymentRef.current.select()
		}
	}

	function validateButton() {
		//debugger
		if (!(data.totalAmount)) {
			return true
		}
		if (!(data.clientCode)) {
			return true
		}
		if (!(data.writeOff || data.totalAmount)) {
			return true
		}
		if (!(data.writeOff <= client.balance)) {
			return true
		}
		if (!(client.paid)) {
			return true
		}
	}

	async function createClient(validatedData) {
		var sendData = { ...validatedData }
		sendData.carId = data.carId
		var response = null
		if (sendData.id) {
			response = await PUT('/services/redeemapi/api/user', sendData, { loader: true })
		} else {
			sendData.login = "998" + sendData.login
			response = await POST('/services/redeemapi/api/user', sendData, { loader: true })
		}

		if (response) {
			setShowModal(false)
			reset()
		}
	}

	function openModal(bool = false) {
		if (bool) {
			for (const [key, value] of Object.entries(client)) {
				setValue(key, value)
			}
		}
		setShowModal(true)
	}

	function closeModal() {
		setShowModal(false)
		reset()
	}

	async function getCarTypes() {
		const response = await GET('/services/mobile/api/car-type-helper')
		setCarTypes(response)
	}

	function checkStatus(tabNumber) {
		var status = ""
		switch (tabNumber) {
			case 1:
				status = "border-success"
				break;
			case 2:
				status = "border-danger"
				break;
			case 3:
				status = "border-dark"
				break;
			default:
				status = "border-success"
				break;
		}

		return status
	}

	function changeAmount(amount) {
		var totalAmount = (Number(data.totalAmount.replace(" ", "")) + amount).toString()
		totalAmount = formatMoneyInput(totalAmount.replace(/[^0-9.]/g, ''))
		setData({ ...data, 'totalAmount': totalAmount })
	}

	function checkExpMethane(expDate) {
		const x = new Date();
		const y = new Date(expDate);

		if (x > y) {
			return 'text-danger'
		}
	}

	function calculateWriteOff() {
		var totalAmount = data.totalAmount?.replace(" ", "")

		var result;
		if (data.writeOff) {
			result = (totalAmount - ((totalAmount - data.writeOff) * client.award / 100)) - data.writeOff
			return formatMoney(result, 2)
		} else {
			result = totalAmount - ((totalAmount - data.writeOff) * client.award / 100)
			return formatMoney(result, 0)
		}
	}

	useEffect(() => {
		getCarTypes()
	}, [])

	return (
		<>
			<div className="page-header mb-2">
				<div className="row">
					<div className="col-md-6">
						<div className="d-flex">
							<button className="btn-rounded btn btn-primary" onClick={() => openModal(false)}>
								<i className="uil uil-plus me-2"></i>
								{t('CLIENT')}
							</button>
						</div>
					</div>
					<div className="col-md-6">
						<div className="d-flex justify-content-center">
							<button className="btn btn-success me-2"
								style={{ 'width': '100%' }}
								onClick={() => setActiveTab(1)}>
								{t('CASHBOX')}
							</button>
							<button className="btn btn-danger me-2"
								style={{ 'width': '100%' }}
								onClick={() => setActiveTab(2)}>
								{t('WRITE_OFF')}
							</button>
							<button className="btn btn-dark me-2"
								style={{ 'width': '100%' }}
								onClick={() => setActiveTab(3)}>
								{t('DEBT')}
							</button>
						</div>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-md-6">
					<div className="card h-100 position-relative">
						{client.login &&
							<div className="user-edit-btn">
								<div className="table-action-button table-action-primary-button"
									onClick={() => openModal(true)}>
									<i className="uil-edit-alt"></i>
								</div>
							</div>
						}
						<div className="card-body">
							<div className="text-center">
								<h1><b>{t('BALANCE')}: {formatMoney(client.balance)}</b></h1>
							</div>
							<div className="d-flex justify-content-between mb-3">
								<div>
									<i className="uil uil-user me-3"></i>
									<span>{t('CLIENT')}</span>
								</div>
								<div>{client.name}</div>
							</div>
							<div className="d-flex justify-content-between mb-3">
								<div>
									<i className="uil uil-mobile-android-alt me-3"></i>
									<span>{t('PHONE_NUMBER')}</span>
								</div>
								<div>{regexPhoneNumber(client.login)}</div>
							</div>
							<div className="d-flex justify-content-between mb-3">
								<div>
									<i className="uil uil-dialpad-alt me-3"></i>
									<span>{t('CAR_NUMBER')}</span>
								</div>
								<div>{client.carNumber}</div>
							</div>
							<div className="d-flex justify-content-between mb-3">
								<div>
									<i className="uil uil-car me-3"></i>
									<span>{t('CAR_TYPE')}</span>
								</div>
								<div>{client.carTypeName}</div>
							</div>
							<div className="d-flex justify-content-between mb-3">
								<div>
									<i className="uil uil-bill me-3"></i>
									<span>{t('MAXIMUM_AMOUNT')}</span>
								</div>
								{client.carTypeAmount &&
									<div><b>{formatMoney(client.carTypeAmount, 0)}</b></div>
								}
							</div>
							<div className="d-flex justify-content-between mb-3">
								<div>
									<i className="uil uil-airplay me-3"></i>
									<span>{t('STATUS')}</span>
								</div>
								<div>{client.status}</div>
							</div>
							<div className="d-flex justify-content-between mb-3">
								<div>
									<i className="uil uil-award me-3"></i>
									<span>{t('BONUS')}</span>
								</div>
								{client.award &&
									<div>{client.award}%</div>
								}
							</div>
							<div>
								<div className="d-flex justify-content-between mb-3">
									<div>
										<i className="uil uil-credit-card-search me-3"></i>
										<span>{t('DEBT')}</span>
									</div>
									<div>{client.balanceDebt}</div>
								</div>
								<div className="d-flex justify-content-between mb-3">
									<div>
										<i className="uil uil-calender me-3"></i>
										<span>{t('RETURN_DATE')}</span>
									</div>
									<div>{formatDate(client.returnDebtDate)}</div>
								</div>
							</div>

							<div className="d-flex justify-content-between mb-3">
								<div>
									<i className="uil uil-pump me-3"></i>
									<span>{t('GAS_BALON')}</span>
								</div>
								<div className={checkExpMethane(client.expMethane)}>{formatDate(client.expMethane)}</div>
							</div>

							<div className="table-responsive">
								<table className="table table-striped mb-0">
									<thead>
										<tr>
											<th>{t('POS')}</th>
											<th className="text-center">{t('AMOUNT')}</th>
											<th className="text-center">{t('WRITE_OFF')}</th>
											<th className="text-center">{t('DATE')}</th>
										</tr>
									</thead>
									<tbody>
										{client?.chequeList.map((item, index) => (
											<tr key={index}>
												<td>{index + 1}. {item.posName}</td>
												<td className="text-center">{formatMoney(item.totalAmount)}</td>
												<td className="text-center">{formatMoney(item.writeOff)}</td>
												<td className="text-center">
													{item.debt ?
														<>
															<span className="bg-dark bg-border text-white">{formatDateWithTime(item.chequeDate)}</span>
														</>
														:
														<>
															{!item.cancel &&
																<span className="bg-success bg-border text-white">{formatDateWithTime(item.chequeDate)}</span>
															}
															{item.cancel &&
																<span className="bg-danger bg-border text-white">{formatDateWithTime(item.chequeDate)}</span>
															}
														</>
													}
												</td>
											</tr>
										))
										}
									</tbody>
								</table>
							</div>

						</div>
					</div>
				</div>
				<div className="col-md-6">
					<div className="card">
						<div className={'card-body ' + (checkStatus(activeTab))}>
							{activeTab === 1 &&
								<div>
									<div className="d-flex">
										<div className="form-group w-100 me-3">
											<label className="form-label">{t('CLIENT_CODE')}</label>
											<input className="form-control" ref={clientCodeRef} value={data.clientCode}
												type="number"
												autoFocus
												placeholder={t('SEARCH') + '...'}
												onChange={(e) => setData({ ...data, 'clientCode': e.target.value })}
												onKeyUp={(e) => {
													if (e.keyCode === 13) {
														requestToServer(e.target.value)
													}
												}}
											/>
										</div>
										<div className="form-group w-100">
											<label className="form-label">{t('CAR_NUMBER')}</label>
											<input className="form-control" ref={clientCarNumberRef} value={data.carNumberSearch}
												type="text"
												placeholder=""
												onChange={(e) => setData({ ...data, 'carNumberSearch': e.target.value })}
												onKeyUp={(e) => {
													if (e.keyCode === 13) {
														requestToServerCar(e.target.value)
													}
												}}
											/>
										</div>
									</div>
									<div className="d-flex mb-3">
										{client?.carsList?.map((item, index) => (
											<div className={"car-item " + (item.carId === data.carId ? 'active ' : '') + (item.carCompany ? 'company' : '')}
												key={index} onClick={() => updateCar(item)}>
												{item.carNumber}
											</div>
										))
										}
									</div>
									<div className="form-group">
										<label className="form-label">
											<span className="me-3">{t('AMOUNT')} <b className="text-danger">[{formatMoney(client.carTypeAmount, 0)}]</b></span>
										</label>
										<div className="position-relative">
											<input className="form-control" ref={paymentRef} value={data.totalAmount}
												disabled={client.carCompany && data.writeOff}
												onChange={(e) => setData({ ...data, 'totalAmount': formatMoneyInput(e.target.value.replace(/[^0-9.]/g, '')) })}
												onKeyUp={(e) => {
													if (e.keyCode === 107) {
														createCheque(1)
													}
												}} />
											<span className="show-point">
												[{formatMoney((data.totalAmount?.replaceAll(" ", "") - data.writeOff) * client.award / 100, 0)} B]
											</span>
										</div>
										<div className="d-flex mt-3">
											<span className="fast-pay" onClick={() => changeAmount(1000)}>{formatMoney(1000, 0)}</span>
											<span className="fast-pay" onClick={() => changeAmount(2000)}>{formatMoney(2000, 0)}</span>
											<span className="fast-pay" onClick={() => changeAmount(5000)}>{formatMoney(5000, 0)}</span>
											<span className="fast-pay" onClick={() => changeAmount(10000)}>{formatMoney(10000, 0)}</span>
											<span className="fast-pay" onClick={() => changeAmount(20000)}>{formatMoney(20000, 0)}</span>
											<span className="fast-pay" onClick={() => changeAmount(30000)}>{formatMoney(30000, 0)}</span>
											<span className="fast-pay" onClick={() => changeAmount(40000)}>{formatMoney(40000, 0)}</span>
											<span className="fast-pay" onClick={() => changeAmount(50000)}>{formatMoney(50000, 0)}</span>
										</div>
									</div>
									<div className="form-group">
										<label className="form-label">{t('WRITE_OFF')}</label>
										<input className={"form-control " + ((data.writeOff > client.balance) && "required-border")}
											disabled={!(client.balance > 0)} value={data.writeOff}
											onChange={(e) => setData({ ...data, 'writeOff': e.target.value, totalAmount: client.carCompany ? '' : data.totalAmount })}
											onKeyUp={(e) => {
												if (e.keyCode === 13) {
													createCheque(1)
												}
											}} />
									</div>
									<div className="d-flex">
										<button type="button" className="btn btn-danger mt-3 w-100 me-2"
											disabled={validateButton()}
											onClick={() => createCheque(2)}>
											<span className="me-2">{t('WRITE_OFF')}</span>
											<span>{calculateWriteOff()}</span>
										</button>
										<button type="button" className="btn btn-success mt-3 w-100"
											disabled={validateButton()}
											onClick={() => createCheque(1)}>
											{t('PAY')}
										</button>
									</div>

									{!client.paid &&
										<div className="text-center text-danger mt-2">
											{t('PAY_AGAIN')}
										</div>
									}
								</div>
							}
							{activeTab === 2 &&
								<div>
									<div className="form-group">
										<label className="form-label">{t('CLIENT_CODE')}</label>
										<input className="form-control" ref={clientCodeRef} value={writeOffData.clientCode}
											type="number"
											autoFocus
											placeholder={t('SEARCH') + '...'}
											onChange={(e) => setWriteOffData({ ...writeOffData, 'clientCode': e.target.value })}
											onKeyUp={(e) => {
												if (e.keyCode === 13) {
													requestToServer(e.target.value, 'writeOff')
												}
											}}
										/>
									</div>
									<div className="form-group">
										<label className="form-label">{t('WRITE_OFF')}</label>
										<input className={"form-control " + ((writeOffData.writeOff > client.balance) && "required-border")}
											ref={writeOffRef}
											disabled={!(client.balance > 0)} value={writeOffData.writeOff}
											onChange={(e) => setWriteOffData({ ...writeOffData, 'writeOff': e.target.value })}
											onKeyUp={(e) => {
												if (e.keyCode === 13) {
													createWriteOff()
												}
											}} />
									</div>
									<button type="button" className="btn btn-danger mt-3 w-100"
										disabled={!(client.balance >= writeOffData.writeOff)}
										onClick={() => createWriteOff()}>
										{t('WRITE_OFF2')}
									</button>
								</div>
							}
							{activeTab === 3 &&
								<div>
									<div className="form-group">
										<label className="form-label">{t('CLIENT_CODE')}</label>
										<input className="form-control" ref={clientCodeRef} value={debtData.clientCode}
											type="number"
											autoFocus
											placeholder={t('SEARCH') + '...'}
											onChange={(e) => setDebtData({ ...debtData, 'clientCode': e.target.value })}
											onKeyUp={(e) => {
												if (e.keyCode === 13) {
													requestToServer(e.target.value, 'debt')
												}
											}}
										/>
									</div>
									<div className="form-group">
										<label className="form-label">{t('DEBT')}</label>
										<input className={"form-control"}
											ref={debtRef}
											value={debtData.debt}
											onChange={(e) => setDebtData({ ...debtData, 'debt': e.target.value })}
											onKeyUp={(e) => {
												if (e.keyCode === 13) {
													createDebt(1)
												}
											}} />
									</div>
									<div className="form-group">
										<label className="form-label">{t('DATE')}</label>
										<div className="calendar-input">
											<DatePicker
												className="form-control"
												dateFormat="dd.MM.yyyy"
												selected={debtData.returnDate ? new Date(debtData.returnDate) : ''}
												onChange={(date) => setDebtData({ ...debtData, 'returnDate': formatDateBackend(date) })} />
											<i className="uil uil-calendar-alt"></i>
										</div>
									</div>
									<div className="d-flex">
										<button type="button" className="btn btn-danger mt-3 w-100 me-2"
											disabled={!(debtData.debt)}
											onClick={() => createDebt(2)}>
											{t('CANCEL_DEBT')}
										</button>
										<button type="button" className="btn btn-success mt-3 w-100"
											disabled={!(debtData.debt)}
											onClick={() => createDebt(1)}>
											{t('DEBT2')}
										</button>
									</div>
								</div>
							}
						</div>
					</div>
				</div>
			</div>

			{/* MODAL UPDATE */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('CREATE')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(createClient)} autoComplete="off">
						<div className="form-group">
							<label>{t('NAME')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="name" {...register('name', { required: true })} />
							<span className="text-danger text-sm">{errors.name?.type === 'required' && t('required')}</span>
						</div>
						{!getValues('id') &&
							<div className="form-group">
								<label>{t('LOGIN')}<span className="required-mark">*</span></label>
								<div className="input-group">
									<span className="input-group-text">998</span>
									<input type="number" className="form-control" name="login" {...register('login', { required: getValues('id') ? true : false, minLength: 9 })} />
								</div>
								<span className="text-danger text-sm">{errors.login?.type === 'required' && t('required')}</span>
								<span className="text-danger text-sm">{errors.login?.type === 'max' && t('max', { length: 9 })}</span>
								<span className="text-danger text-sm">{errors.login?.type === 'min' && t('min', { length: 9 })}</span>
							</div>
						}
						<div className="form-group">
							<label>{t('CAR_NUMBER')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" placeholder="01X000XX"
								name="carNumber" {...register('carNumber', { required: true })} />
							<span className="text-danger text-sm">{errors.carNumber?.type === 'required' && t('required')}</span>
						</div>
						<div className="form-group">
							<label>{t('CAR_TYPE')}<span className="required-mark">*</span></label>
							<Controller
								{...register('carTypeId', { required: true })}
								name="carTypeId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										options={carTypes}
										value={carTypes.find(option => option.id === getValues('carTypeId')) || ''}
										onChange={(e) => setValue('carTypeId', e.id)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>}
							/>
							<span className="text-danger text-sm">{errors.carTypeId?.type === 'required' && t('required')}</span>
						</div>
						{(!getValues('id') || getValues('cardNumber') === null) &&
							<div className="form-group">
								<label>{t('CARD_NUMBER')}</label>
								<input type="number" className="form-control" name="cardNumber" {...register('cardNumber', { required: false })} />
							</div>
						}
						{!getValues('id') &&
							<div className="form-group">
								<label>{t('PASSWORD')}<span className="required-mark">*</span></label>
								<input type="text" className="form-control" name="password" 	{...register('password', { required: true })} />
								<span className="text-danger text-sm">{errors.password?.type === 'required' && t('required')}</span>
							</div>
						}
						{getValues('id') &&
							<input type="number" className="form-control d-none" name="password" {...register('password', { required: false })} />
						}
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL UPDATE */}
		</>
	)
}

export default Index