export let exportMenuCompany = [
	{
		url: "/my-company/company",
		i18n: "COMPANY",
		icon: "uil-shop",
		active: false,
	},
	{
		url: "/my-company/cars",
		i18n: "CARS",
		icon: "uil-car",
		active: false,
	},
]