import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import { PGET, GET, POST } from 'helpers/api'
import { regexPhoneNumber } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const { register, handleSubmit, formState, control, reset, setValue, getValues } = useForm({
		mode: "onChange",
		resolver: yupResolver(validationSchema),
	});
	const { errors } = formState

	const [modalAddCard, setModalAddCard] = useState({ 'bool': false, 'index': 0 })
	const [showModal, setShowModal] = useState(false)
	const [clientCard, setClientCard] = useState({
		'posId': 0,
		'cardNumber': "",
	})
	const [carTypes, setCarTypes] = useState([])
	const [data, setData] = useState([])
	const [pagination] = useState({
		url: '/services/redeemapi/api/user-pageList',
		response: null
	})
	const [pageCount, setPageCount] = useState(0)

	async function createClient(validatedData) {
		var sendData = { ...validatedData }
		sendData.login = "998" + sendData.login

		const response = await POST('/services/redeemapi/api/user', sendData, { loader: true })

		if (response) {
			setShowModal(false)
			reset()
		}
	}

	async function createCard(e) {
		e.preventDefault();

		var sendData = {
			'id': data[modalAddCard.index]['id'],
			'cardNumber': clientCard.cardNumber,
		}

		const response = await POST('/services/redeemapi/api/user-card', sendData, { loader: true })
		if (response) {
			setModalAddCard({ 'bool': false, 'index': 0 })
			setClientCard({
				'cardNumber': "",
			})
			getData()
		}
	}

	function openModal() {
		setShowModal(true)
	}

	function closeModal() {
		setShowModal(false)
		reset()
	}

	async function paginate(data) {
		const response = await PGET(pagination.url, { page: data.selected, size: 20 }, { loader: true })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	async function getData() {

		const response = await PGET("/services/redeemapi/api/user-pageList")
		if (response) {
			setPageCount(Math.ceil(response.total / 20))
			setData(response.data)
		}
	}

	async function getCarTypes() {
		const response = await GET('/services/mobile/api/car-type-helper')
		setCarTypes(response)
	}


	useEffect(() => {
		getCarTypes()
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('CLIENTS')}
				</h4>
				<div className="d-flex d-none">
					<button className="btn-rounded btn btn-primary" onClick={openModal}>
						<i className="uil uil-plus me-2"></i>
						{t('CLIENT')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex mb-3">
						<div>
							<Search url={pagination.url} search={search} getData={getData}></Search>
						</div>
					</div>

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>№ {t('NAME')}</th>
									<th>{t('PHONE')}</th>
									<th>{t('CAR_NUMBER')}</th>
									<th>{t('CAR_TYPE')}</th>
									<th className="text-center">{t('BALANCE')}</th>
									<th className="text-center">{t('CARD_NUMBER')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>
											<Link className="text-primary cursor table-first-column-clickable"
												to={'/cashier-clients/' + item.id}>
												{index + 1}. {item.name}
											</Link>
										</td>
										<td>{regexPhoneNumber(item.login)}</td>
										<td>{item.carNumber}</td>
										<td>{item.carTypeName}</td>
										<td className="text-center">{item.balance}</td>
										<td>
											{!item.cardNumber ?
												<div className="d-flex justify-content-center">
													<div className="table-action-button table-action-primary-button"
														onClick={() => setModalAddCard({ 'bool': true, 'index': index })}>
														<i className="uil-plus"></i>
													</div>
												</div>
												:
												<div className="text-center">
													{item.cardNumber}
												</div>
											}
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('CREATE')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(createClient)} autoComplete="off">
						<div className="form-group">
							<label>{t('NAME')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="name" {...register('name', { required: true })} />
							<span className="text-danger text-sm">{errors.name?.type === 'required' && t('required')}</span>
						</div>
						<div className="form-group">
							<label>{t('LOGIN')}<span className="required-mark">*</span></label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control" name="phone" {...register('login', { required: true, maxLength: 9, minLength: 9 })} />
							</div>
							<span className="text-danger text-sm">{errors.login?.type === 'required' && t('required')}</span>
							<span className="text-danger text-sm">{errors.login?.type === 'maxLength' && t('max', { length: 9 })}</span>
							<span className="text-danger text-sm">{errors.login?.type === 'minLength' && t('min', { length: 9 })}</span>
						</div>
						<div className="form-group">
							<label>{t('CAR_NUMBER')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" placeholder="01X000XX"
								name="carNumber" {...register('carNumber')} />
						</div>
						<div className="form-group">
							<label>{t('CAR_TYPE')}<span className="required-mark">*</span></label>
							<Controller
								name="carTypeId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										options={carTypes}
										value={carTypes.find(option => option.id === getValues('carTypeId')) || ''}
										onChange={(e) => setValue('carTypeId', e.id)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>}
							/>
							<span className="text-danger text-sm">{errors.carTypeId?.type === 'required' && t('required')}</span>
						</div>
						<div className="form-group">
							<label>{t('CARD_NUMBER')}</label>
							<input type="number" className="form-control" name="cardNumber" {...register('cardNumber')} />
						</div>
						<div className="form-group">
							<label>{t('PASSWORD')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="password" {...register('password')} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}


			{/* MODAL ADD CARD */}
			<Modal show={modalAddCard.bool} animation={false} centered dialogClassName="update-modal-width"
				onHide={() => setModalAddCard({ 'bool': false, 'index': 0 })}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('CREATE')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => createCard(e)} autoComplete="off">
						<div className="form-group">
							<label>{t('CARD_NUMBER')}</label>
							<input type="number" className="form-control" name="cardNumber" autoFocus
								onChange={(e) => setClientCard({ ...clientCard, 'cardNumber': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL ADD CARD */}
		</>
	)
}

export default Index
