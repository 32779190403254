export let exportMenu2 = [
	{
		url: "/pos",
		i18n: "POSES",
		icon: "uil-shop",
		active: false,
	},
	{
		url: "/directors",
		i18n: "DIRECTOR",
		icon: "uil-user",
		active: false,
	},
	{
		url: "/documents",
		i18n: "DOCUMENTS",
		icon: "uil-file-exclamation-alt",
		active: false,
	},
	{
		url: "/cashiers",
		i18n: "CASHIERS",
		icon: "uil-user-square",
		active: false,
	},
	{
		url: "/services",
		i18n: "CASHBACK",
		icon: "uil-apps",
		active: false,
	},
	{
		url: "/prize",
		i18n: "PRIZE",
		icon: "uil-award",
		active: false,
	},
	{
		url: "/gas-columns",
		i18n: "GAS_COLUMN",
		icon: "uil-fire",
		active: false,
	},
	{
		url: "/gas-columns-report",
		i18n: "GAS_COLUMN_REPORT",
		icon: "uil-fire",
		active: false,
	},
	{
		url: "/shifts",
		i18n: "SHIFTS",
		icon: "uil-clock",
		active: false,
	},
]