import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation'

import { GET, POST, PUT, FILE } from 'helpers/api'
import { formatDateBackend } from "helpers/helpers"

function Index() {
	const { t } = useTranslation()

	const { register, handleSubmit, formState, control, setValue, getValues, reset } = useForm({
		mode: "onChange",
		resolver: yupResolver(validationSchema),
	});
	const { errors } = formState

	const [data, setData] = useState([])
	const [selectedItem, setSelectedItem] = useState({})
	const [showModal, setShowModal] = useState(false)
	const [poses, setPoses] = useState([])
	const [documents, setDocuments] = useState([])
	const [sendData, setSendData] = useState({})

	async function createUpdate(validatedData) {

		var selectedItemCopy = validatedData
		var response;
		if (selectedItemCopy.id) {
			selectedItemCopy.imageUrlList = sendData?.imageUrlList
			response = await PUT('/services/web/api/document', selectedItemCopy, { loader: true })
		} else {
			selectedItemCopy.imageUrlList = sendData?.imageUrlList
			response = await POST('/services/web/api/document', selectedItemCopy, { loader: true })
		}

		if (response) {
			getData()
			setShowModal(false)
		}
	}

	function openModal(item) {
		var itemCopy = { ...item }
		setSelectedItem(itemCopy)
		setSendData({ ...sendData, 'imageUrlList': itemCopy.imageUrlList })
		for (const [key, value] of Object.entries(itemCopy)) {
			setValue(key, value)
		}
		setShowModal(true)
	}

	function closeModal() {
		setShowModal(false)
	}

	async function multiFileUpload(e) {
		let formData = new FormData();
		for (let i = 0; i < e.target.files.length; i++) {
			formData.append('image', e.target.files[i])
		}

		const response = await FILE('/services/web/api/upload/images', formData);
		var imageUrlList = []
		for (let i = 0; i < response.length; i++) {
			imageUrlList.push(response[i]);
		}
		formData.delete('image')
		setSendData({ ...sendData, 'imageUrlList': imageUrlList })
	}

	function deleteFromImageUrlList(index) {
		var sendDataCopy = { ...sendData }
		sendDataCopy.imageUrlList.splice(index, 1);
		setSendData(sendDataCopy)
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)
		}
	}

	async function getDocuments() {
		const response = await GET('/services/web/api/document-helper')
		if (response) {
			setDocuments(response)
		}
	}

	async function getData() {
		const response = await GET('/services/web/api/document-pageList', {}, { loader: true })
		if (response) {
			setData(response)
		}
	}

	useEffect(() => {
		getData()
		getPoses()
		getDocuments()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('DOCUMENTS')}
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary" onClick={() => {
						reset()
						setSelectedItem({})
						setShowModal(true)
					}}>
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('POS')}</th>
									<th>{t('DOCUMENT_TYPE')}</th>
									<th className="text-center">{t('DOCUMENT_NUMBER')}</th>
									<th className="text-center">{t('DOCUMENT_DATE')}</th>
									<th className="text-center">{t('EXPIRATION_DATE')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td>{index + 1}. {item.posName}</td>
										<td>{item.documentTypeName}</td>
										<td className="text-center text-nowrap">{item.docNumber}</td>
										<td className="text-center text-nowrap">{item.docDate}</td>
										<td className="text-center text-nowrap">{item.expDate}</td>
										<td className="d-flex justify-content-center">
											<div className="table-action-button table-action-primary-button" onClick={() => openModal(item)}>
												<i className="uil-edit-alt"></i>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedItem.id ?
							<>{t('EDIT')}</>
							:
							<>{t('CREATE')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(createUpdate)} autoComplete="off">
						<div className="form-group">
							<label>{t('POS')}<span className="required-mark">*</span></label>
							<Controller
								name="posId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										options={poses}
										value={poses.find(option => option.id === getValues('posId'))}
										onChange={(e) => setValue('posId', e.id)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>}
							/>
							<span className="text-danger text-sm">{errors.posId?.message}</span>
						</div>
						<div className="form-group">
							<label>{t('DOCUMENT_TYPE')}<span className="required-mark">*</span></label>
							<Controller
								name="documentTypeId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										options={documents}
										value={documents.find(option => option.id === getValues('documentTypeId'))}
										onChange={(e) => setValue('documentTypeId', e.id)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>}
							/>
							<span className="text-danger text-sm">{errors.documentTypeId?.message}</span>
						</div>
						<div className="form-group">
							<label>{t('DOCUMENT_NUMBER')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="docNumber" {...register('docNumber')} />
							<span className="text-danger text-sm">{errors.docNumber?.message}</span>
						</div>
						<div className="form-group">
							<label>{t('DATE')}</label>
							<Controller
								name="docDate"
								control={control}
								render={({ field }) =>
									<DatePicker
										{...field}
										className="form-control"
										dateFormat="dd.MM.yyyy"

										selected={getValues('docDate') ? new Date(getValues('docDate')) : ''}
										onChange={(date) => setValue('docDate', formatDateBackend(date))}
									/>}
							/>
						</div>
						<div className="form-group">
							<label>{t('EXPIRATION_DATE')}</label>
							<Controller
								name="expDate"
								control={control}
								render={({ field }) =>
									<DatePicker
										{...field}
										className="form-control"
										dateFormat="dd.MM.yyyy"
										showYearDropdown
										selected={getValues('expDate') ? new Date(getValues('expDate')) : ''}
										onChange={(date) => setValue('expDate', formatDateBackend(date))}
									/>}
							/>
						</div>
						<div className="form-group">
							<label>{t('FILE')}<span className="required-mark">*</span></label>
							<input type="file" multiple className="form-control"
								onChange={(e) => multiFileUpload(e)}
							/>
						</div>
						<div className="row">
							{sendData?.imageUrlList?.map((item, index) => (
								<div className="col-md-6" key={index}>
									<div className="con-upload">
										<div className="con-img-upload">
											{sendData.imageUrlList.length > 0 &&
												<div className="img-upload">
													<button type="button" className="btn-x-file" onClick={() => deleteFromImageUrlList(index)}>
														<i className="uil-times"></i>
													</button>
													<img src={'https://cabinet.redeem.uz' + sendData.imageUrlList[index]}
														style={{ maxWidth: 'none', maxHeight: '100%' }} alt="react" />
												</div>
											}
										</div>
									</div>
								</div>
							))
							}
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
