import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { PGET, GET } from 'helpers/api'
import { formatDateWithTime, returnSign, formatDateBackend, findFromArrayById, formatMoney, formatDate } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const statuses = [{ "id": 1, "name": t('SUCCESS') }, { "id": 2, "name": t('RETURN') }, { "id": 3, "name": t('DEBT') }]

	const [poses, setPoses] = useState([])
	const [cashiers, setCashiers] = useState([])

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		posId: null,
		cashierId: null,
		status: null,
		fromDate: null,
		toDate: null,
		search: ''
	})
	const [pagination, setPagination] = useState({
		url: '/services/web/api/cheque-pageList',
		response: null
	})
	const [data, setData] = useState([])

	async function searchWithFilter(exportToExcel = false) {
		var filterUrl = "/services/web/api/cheque-pageList"
		var urlParams = ""
		checkFilter()

		if (filterData.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterData.posId
		if (filterData.cashierId)
			urlParams += returnSign(urlParams) + 'cashierId=' + filterData.cashierId
		if (filterData.clientName)
			urlParams += returnSign(urlParams) + 'clientName=' + filterData.clientName
		if (filterData.status)
			urlParams += returnSign(urlParams) + 'status=' + filterData.status
		if (filterData.fromDate)
			urlParams += returnSign(urlParams) + 'fromDate=' + filterData.fromDate
		if (filterData.toDate)
			urlParams += returnSign(urlParams) + 'toDate=' + filterData.toDate
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if ("/services/web/api/cheque-pageList" + urlParams !== filterUrl && !exportToExcel)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await PGET(filterUrl, {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response })
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			posId: null,
			cashierId: null,
			status: null,
			fromDate: null,
			toDate: null,
			search: ''
		})
		setFilterDataExist(false)
		getData()
	}

	async function getData() {
		const response = await PGET('/services/web/api/cheque-pageList', {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': '/services/web/api/cheque-pageList', 'response': response })
		}
	}

	async function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.cashierId === null &&
			filterData.clientName === null &&
			filterData.status === null &&
			filterData.fromDate === null &&
			filterData.toDate === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)
		}
	}

	async function getCashiers() {
		const response = await GET('/services/web/api/cashier-helper')
		if (response) {
			setCashiers(response)
		}
	}

	async function paginate(newPageData) {
		setData(newPageData)
	}

	useEffect(() => {
		getPoses()
		getCashiers()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<h4 className="vertical-center">
					{t('CHEQUES')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div>
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
						</div>
						<div>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('POS')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.posId) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
										<div className="form-group col-md-6">
											<label>{t('STATUS')}</label>
											<Select
												options={statuses}
												value={statuses.find(option => option.id === filterData.status) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'status': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('CLIENT')}</label>
											<input type="text" className="form-control"
												onChange={(e) => setFilterData({ ...filterData, 'clientName': e.target.value })} />
										</div>
										<div className="form-group col-md-6">
											<label>{t('CASHIER')}</label>
											<Select
												options={cashiers}
												value={cashiers.find(option => option.id === filterData.cashierId) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'cashierId': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('PERIOD')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.fromDate ? new Date(filterData.fromDate) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'fromDate': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label className="invisible">.</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.toDate ? new Date(filterData.toDate) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'toDate': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('APPLY')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{(filterData.fromDate || filterData.toDate) &&
									<span className="me-2">
										<b>{t('PERIOD')}: </b>
										{filterData.fromDate &&
											<span>{formatDate(filterData.fromDate)}</span>
										}
										{(filterData.fromDate && filterData.toDate) &&
											<span>|</span>
										}
										{filterData.toDate &&
											<span>{formatDate(filterData.toDate)}</span>
										}
									</span>
								}
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.cashierId &&
									<span className="me-2">
										<b>{t('CASHIER')}: </b> {findFromArrayById(cashiers, filterData.cashierId)}
									</span>
								}
								{filterData.status &&
									<span className="me-2">
										<b>{t('STATUS')}: </b> {findFromArrayById(statuses, filterData.status)}
									</span>
								}
								{filterData.clientName &&
									<span className="me-2">
										<b>{t('CLIENT')}: </b> {filterData.clientName}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-hover mb-0">
							<thead>
								<tr>
									<th>{t('DATE')}</th>
									<th className="text-center">{t('AMOUNT')}</th>
									<th className="text-center">{t('PAID_WITH_BALLS')}</th>
									<th className="text-center">{t('BONUS')}</th>
									<th className="text-center">{t('CLIENT')}</th>
									<th className="text-center">{t('CASHIER')}</th>
									<th className="text-center">{t('POS')}</th>
									<th className="text-center">{t('STATUS')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className={'cursor ' + (item.showSidebar ? 'table-tr-active' : '')}
										key={index}>
										<td>{item.rowNum}. {formatDateWithTime(item.chequeDate)}</td>
										<td className="text-center">{formatMoney(item.totalAmount)}</td>
										<td className="text-center">{formatMoney(item.writeOff)}</td>
										<td className="text-center">{formatMoney(item.bonus)}</td>
										<td className="text-center">{item.clientName}</td>
										<td className="text-center">{item.cashierName}</td>
										<td className="text-center">{item.posName}</td>
										<td className="d-flex justify-content-center">
											{item.debt ?
												<>
													<span className="bg-dark bg-border text-white">{t('DEBT')}</span>
												</>
												:
												<>
													{!item.cancel &&
														<span className="bg-success bg-border text-white">{t('SUCCESS')}</span>
													}
													{item.cancel &&
														<span className="bg-danger bg-border text-white">{t('RETURN')}</span>
													}
												</>
											}
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

		</>
	)
}

export default Index
