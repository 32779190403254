import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom";
import { GUESTGET } from "helpers/api";

function Promotion() {
	const { id } = useParams();

	const [data, setData] = useState({});

	async function getData() {
		const response = await GUESTGET(`/services/web/api/promotion/${id}`, {}, { loader: true })
		setData(response)
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	function createMarkup(tag) {
		return { __html: tag.description };
	}

	return (
		<>
			<div className="container promotion-body">
				<div className="row">
					<div className="col-md-12">
						<h1 className="text-center">{data.posName}</h1>
						<h3 className="text-center">{data.name}</h3>
						<div className="d-flex justify-content-center">
							<img src={'https://cabinet.redeem.uz' + (data.imageUrl ? data.imageUrl : '')} alt="react" />
						</div>
						<div dangerouslySetInnerHTML={createMarkup(data)} />
					</div>
				</div>
			</div>
		</>
	)
}

export default Promotion