import React, { useEffect, useRef, useState } from "react"
import { Link, useLocation, useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'

import { setNavTab } from 'store/layout/actions';
//Import Scrollbar
import SimpleBar from "simplebar-react"
import { Nav } from 'react-bootstrap'
import { exportMenu } from './menu'
import { exportMenu2 } from './menu2'
import { exportMenuDirector } from './menuDirector'
import { exportMenuCashier } from './menuCashier'
import { exportMenuCompany } from './menuCompany'

function SidebarContent() {
	const role = localStorage.getItem('role');

	const { t } = useTranslation();
	const ref = useRef();
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch()

	const navTabKey = useSelector(state => state.Layout.navTabKey)

	const [menu, setMenu] = useState([])
	const [menu2, setMenu2] = useState([])

	function setActiveMenu() {
		for (let i = 0; i < menu.length; i++) {

			if (location.pathname.includes(menu[i].url)) {
				showItem(i)
			}
			if (location.pathname.includes(menu[i].url)) {
				if (menu[i].children?.length > 0) {
					showItem(i)
					break;
				}
			}

		}
	}

	function showItem(index) {
		let copy = [...menu];
		let copyItem = { ...menu[index] };
		copyItem.active = !copyItem.active;
		copy[index] = copyItem;
		setMenu(copy);
	}

	function setNav(link) {
		if (link === 'link_3') {
			dispatch(setNavTab('link_1'))
			history.push('/settings')
		} else {
			dispatch(setNavTab(link))
		}
	}

	useEffect(() => {
		switch (role) {
			case "ROLE_CASHIER":
				setMenu(exportMenuCashier)
				setMenu2(exportMenu2)
				break;
			case "ROLE_DIRECTOR":
				setMenu(exportMenu)
				setMenu2(exportMenuDirector)
				break;
			case "ROLE_COMPANY":
				setMenu(exportMenuCompany)
				setMenu2([])
				break;
			default:
				setMenu(exportMenu)
				setMenu2(exportMenu2)
				break;
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setActiveMenu()
	}, [location]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<SimpleBar style={{ maxHeight: "100%" }} ref={ref} className="sidebar-menu-scroll">
				{(role && role !== "ROLE_CASHIER") &&
					<Nav variant="tabs" activeKey={navTabKey}>
						<Nav.Item onClick={() => setNav('link_1')}>
							<Nav.Link eventKey="link_1" tabIndex="-1">
								<span className="fw-bold">{t('MENU')}</span>
							</Nav.Link>
						</Nav.Item>

						{menu2.length ? <Nav.Item onClick={() => setNav('link_2')}>
							<Nav.Link eventKey="link_2" tabIndex="-1">
								<i className="uil-setting"></i>
							</Nav.Link>
						</Nav.Item>
						:
						''
						}
					</Nav>
				}

				{navTabKey === "link_1" &&
					<div id="sidebar-menu">
						<ul className="metismenu list-unstyled" id="side-menu">
							{menu.map((item, index) => (
								<li className={item.active ? "mm-active" : ""} key={index}>
									<Link to={item.url} className={"waves-effect " + (item.active ? "active" : "")} tabIndex="-1">
										<i className={item.icon}></i>
										<span>{t(item.i18n)}</span>
									</Link>
								</li>
							))
							}
						</ul>
					</div>
				}

				{navTabKey === "link_2" &&
					<div id="sidebar-menu">
						<ul className="metismenu list-unstyled" id="side-menu">
							{menu2.map((item, index) => (
								<li className={item.active ? "mm-active" : ""} key={index}>
									<Link to={item.url} className={"waves-effect " + (item.active ? "active" : "")} tabIndex="-1">
										<i className={item.icon}></i>
										<span>{t(item.i18n)}</span>
									</Link>
								</li>
							))
							}
						</ul>
					</div>
				}

			</SimpleBar>
		</>
	)
}

export default SidebarContent