import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'
import { Dropdown } from 'react-bootstrap'
import Select from 'react-select'
import DatePicker from "react-datepicker"

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'
import useDidMountEffect from 'pages/parts/useDidMountEffect'

import { useForm, Controller } from "react-hook-form";

import { PGET, POST, GET, PUT } from 'helpers/api'
import { formatDate, returnSign, findFromArrayById, formatMoney, formatDateBackend } from 'helpers/helpers'

function Index() {
	//const role = localStorage.getItem('role');
	const { t } = useTranslation()

	const genders = [{ "id": 1, "name": t('MAN') }, { "id": 0, "name": t('WOMAN') }]

	const { register, handleSubmit, formState, control, reset, setValue, getValues } = useForm({
		mode: "onChange",
	});
	const { errors } = formState

	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [showModal, setShowModal] = useState(false)

	//const [selectedItem, setSelectedItem] = useState({})
	const [statuses, setStatuses] = useState([])
	const [carTypes, setCarTypes] = useState([])
	const [showModalUpdate, setShowModalUpdate] = useState(false)

	const [notification, setNotification] = useState({
		'title': '',
		'message': '',
		'sendAll': false,
		'usersId': [],
	})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		posId: null,
		ageFrom: null,
		ageTo: null,
		gender: null,
		fromDate: null,
		toDate: null,
		search: ''
	})
	const [pagination, setPagination] = useState({
		url: '/services/web/api/user-pageList',
		response: null
	})

	async function updateClient(validatedData) {
		var sendData = { ...validatedData }

		sendData.posId = filterData.posId

		const response = await PUT('/services/web/api/user', sendData, { loader: true })

		if (response) {
			closeModalUpdate()
			reset()
			searchWithFilter()
		}
	}

	async function sendNotification() {
		setShowModal(false)
		const response = await POST('/services/web/api/send-notification', notification, {})
		if (response) {
			toast.success(t('SUCCESS'))
		}
	}

	function openModal(item) {
		var notificationCopy = { ...notification }
		if (item) {
			notificationCopy.sendAll = false
			notificationCopy.usersId = []
			notificationCopy.usersId.push(item.id)
		} else {
			notificationCopy.sendAll = true
			notificationCopy.usersId = []
		}
		setNotification(notificationCopy)
		setShowModal(true)
	}

	async function openModalUpdate(item) {
		var posParam =''
		if(filterData.posId) {
			posParam = `?posId=${filterData.posId}`
		}
		const responseStatus = await GET(`/services/web/api/rules-helper/${item.carTypeId + posParam}`, {}, { loader: true })
		setStatuses(responseStatus)
		for (const [key, value] of Object.entries(item)) {
			setValue(key, value)
		}
		setShowModalUpdate(true)
	}

	function closeModalUpdate() {
		setShowModalUpdate(false)
		reset()
	}

	async function searchWithFilter() {
		var filterUrl = "/services/web/api/user-pageList"
		var urlParams = ""
		checkFilter()

		if (filterData.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterData.posId
		if (filterData.carTypeId)
			urlParams += returnSign(urlParams) + 'carTypeId=' + filterData.carTypeId
		if (filterData.ageFrom)
			urlParams += returnSign(urlParams) + 'ageFrom=' + filterData.ageFrom
		if (filterData.ageTo)
			urlParams += returnSign(urlParams) + 'ageTo=' + filterData.ageTo
		if (filterData.gender === 0 || filterData.gender === 1)
			urlParams += returnSign(urlParams) + 'gender=' + filterData.gender
		if (filterData.fromDate)
			urlParams += returnSign(urlParams) + 'fromDate=' + filterData.fromDate
		if (filterData.toDate)
			urlParams += returnSign(urlParams) + 'toDate=' + filterData.toDate
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if ("/services/web/api/user-pageList" + urlParams !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await PGET(filterUrl, {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response })
		}

		setDropdownIsOpen(false)
	}

	async function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.ageFrom === null &&
			filterData.ageTo === null &&
			filterData.gender === null &&
			filterData.fromDate === null &&
			filterData.toDate === null &&
			filterData.carTypeId === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function resetFilter() {
		setFilterData({
			posId: null,
			ageFrom: null,
			ageTo: null,
			gender: null,
			fromDate: null,
			toDate: null,
			carTypeId: null,
			search: ''
		})
		setFilterDataExist(false)
		getData()
	}

	async function getData() {
		const response = await PGET('/services/web/api/user-pageList', {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': '/services/web/api/user-pageList', 'response': response })
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper', {}, {})
		if (response) {
			setPoses(response)
			const role = localStorage.getItem('role');
			if (role === 'ROLE_DIRECTOR') {
				setFilterData({ ...filterData, 'posId': response[0]['id'], 'posChanged': true })
			} else {
				searchWithFilter()
			}
		}
	}

	async function getCarTypes() {
		const response = await GET('/services/web/api/car-type-helper')
		setCarTypes(response)
	}

	async function paginate(newPageData) {
		setData(newPageData)
	}

	useEffect(() => {
		getPoses()
		getCarTypes()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (filterData.posChanged) {
			setFilterData({ ...filterData, 'posChanged': false })
			searchWithFilter()
		}
	}, [filterData]) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		searchWithFilter()
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	// useEffect(() => {
	// 	searchWithFilter()
	// }, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('CLIENTS')}
				</h4>
				<button className="btn-rounded btn btn-primary" onClick={() => openModal()}>
					{t('SEND_PUSH_NOTIFICATION_TO_EVERYONE')}
				</button>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
						</div>
						<div>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('POSES')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.posId) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
										<div className="form-group col-md-6">
											<label>{t('GENDER')}</label>
											<Select
												options={genders}
												value={genders.find(option => option.id === filterData.gender) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'gender': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('CAR_TYPE')}</label>
											<Select
												options={carTypes}
												value={carTypes.find(option => option.id === filterData.carTypeId) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'carTypeId': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('AGE_FROM')}</label>
											<input type="text" className="form-control" onChange={(e) => setFilterData({ ...filterData, 'ageFrom': e.target.value })} />
										</div>
										<div className="form-group col-md-6">
											<label>{t('AGE_TO')}</label>
											<input type="text" className="form-control" onChange={(e) => setFilterData({ ...filterData, 'ageTo': e.target.value })} />
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('PERIOD')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.fromDate ? new Date(filterData.fromDate) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'fromDate': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label className="invisible">.</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.toDate ? new Date(filterData.toDate) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'toDate': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button className="btn btn-primary" onClick={() => searchWithFilter()}>{t('APPLY')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{(filterData.fromDate || filterData.toDate) &&
									<span className="me-2">
										<b>{t('PERIOD')}: </b>
										{filterData.fromDate &&
											<span>{formatDate(filterData.fromDate)}</span>
										}
										{(filterData.fromDate && filterData.toDate) &&
											<span>|</span>
										}
										{filterData.toDate &&
											<span>{formatDate(filterData.toDate)}</span>
										}
									</span>
								}
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POSES')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.carTypeId &&
									<span className="me-2">
										<b>{t('CAR_TYPE')}: </b> {findFromArrayById(carTypes, filterData.carTypeId)}
									</span>
								}
								{filterData.ageFrom &&
									<span className="me-2">
										<b>{t('AGE_FROM')}: </b> {filterData.ageFrom}
									</span>
								}
								{filterData.ageTo &&
									<span className="me-2">
										<b>{t('AGE_TO')}: </b> {filterData.ageTo}
									</span>
								}
								{(filterData.gender === 0 || filterData.gender === 1) &&
									<span className="me-2">
										<b>{t('GENDER')}: </b>
										{filterData.gender === 0 ?
											<span>{t('WOMAN')}</span>
											:
											<span>{t('MAN')}</span>
										}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('CLIENT')}</th>
									<th className="text-center">{t('PHONE')}</th>
									<th className="text-center">{t('CAR_TYPE')}</th>
									<th className="text-center">{t('CAR_NUMBER')}</th>
									<th className="text-center">{t('CHECK_AMOUNT')}</th>
									<th className="text-center">{t('BALANCE')}</th>
									<th className="text-center">{t('STATUS')}</th>
									<th className="text-center">{t('DEBT')}</th>
									<th className="text-center">{t('LAST_ACTIVITY')}</th>
									<th className="text-center">{t('TOTAL_POINTS')}</th>
									<th className="text-center">{t('NUMBER_VISITS')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className="tr-middle" key={index}>
										<td>
											<Link className="text-primary cursor table-first-column-clickable"
												to={'/clients/' + item.id}>
												{item.rowNum}.
												{item.name}
											</Link>
										</td>
										<td className="text-center">{item.login}</td>
										<td className="text-center">{item.carTypeName}</td>
										<td className="text-center">{item.carNumber}</td>
										<td className="text-center text-nowrap">{formatMoney(item.sumCheque)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.balance, 0)}</td>
										<td className="text-center">{item.status}</td>
										<td className="text-center text-nowrap">{formatMoney(item.debtAmount, 0)}</td>
										<td className="text-center text-nowrap">{item.lastChequeDate ? formatDate(item.lastChequeDate) : ''}</td>
										<td className="text-center">{formatMoney(item.balanceStatus, 0)}</td>
										<td className="text-center text-nowrap">{item.countCheque}</td>
										<td>
											<div className="d-flex justify-content-center">
												<div className="d-flex justify-content-center me-2">
													<div className="table-action-button table-action-primary-button" onClick={() => openModalUpdate(item)}>
														<i className="uil-edit-alt"></i>
													</div>
												</div>
												<div className="d-flex justify-content-center">
													<div className="table-action-button table-action-primary-button" onClick={() => openModal(item)}>
														<i className="uil-message"></i>
													</div>
												</div>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{notification.sendAll ?
							<div>
								{t('SEND_PUSH_NOTIFICATION_TO_EVERYONE')}
							</div>
							:
							<div>
								{t('SEND_PUSH_NOTIFICATION')}
							</div>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={() => sendNotification()} autoComplete="off">
						<div className="form-group">
							<label>{t('NAME3')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" onChange={(e) => setNotification({ ...notification, 'title': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('DESCRIPTION')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" onChange={(e) => setNotification({ ...notification, 'message': e.target.value })} />
						</div>
						<p className="text-danger">
							{t('YOU_ARE_RESPONSIBLE')}
						</p>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => setShowModal(false)}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100"
								disabled={!notification.title || !notification.message}>{t('SEND')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			{/* MODAL UPDATE */}
			<Modal show={showModalUpdate} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => closeModalUpdate()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('EDIT')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(updateClient)} autoComplete="off">
						<div className="form-group">
							<label>{t('NAME')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="name" {...register('name', { required: true })} />
							<span className="text-danger text-sm">{errors.name?.type === 'required' && t('required')}</span>
						</div>
						<div className="form-group">
							<label>{t('CAR_NUMBER')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" placeholder="01X000XX"
								name="carNumber" {...register('carNumber', { required: true })} />
							<span className="text-danger text-sm">{errors.carNumber?.type === 'required' && t('required')}</span>
						</div>
						<div className="form-group">
							<label>{t('STATUS')}<span className="required-mark">*</span></label>
							<Controller
								{...register('statusId', { required: true })}
								name="statusId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										options={statuses}
										value={statuses.find(option => option.id === getValues('statusId')) || ''}
										onChange={(e) => setValue('statusId', e.id)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.clientStatusName}
										getOptionValue={(option) => option.id}
									/>}
							/>
							<span className="text-danger text-sm">{errors.statusId?.type === 'required' && t('required')}</span>
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModalUpdate()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL UPDATE */}
		</>
	)
}

export default Index
