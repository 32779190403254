export let exportMenu = [
	{
		url: "/dashboard",
		i18n: "HOME",
		icon: "uil-home-alt",
		active: false,
	},
	{
		url: "/company",
		i18n: "COMPANY",
		icon: "uil-shop",
		active: false,
	},
	{
		url: "/cheques",
		i18n: "CHEQUES",
		icon: "uil-invoice",
		active: false,
	},
	{
		url: "/clients",
		i18n: "CLIENTS",
		icon: "uil-users-alt",
		active: false,
	},
	{
		url: "/debtors",
		i18n: "DEBTORS",
		icon: "uil-users-alt",
		active: false,
	},
	{
		url: "/expenses",
		i18n: "EXPENSES",
		icon: "uil-money-insert",
		active: false,
	},
]