import React, { useEffect, useState } from "react"
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import { Modal } from 'react-bootstrap'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { PGET, POST } from 'helpers/api'
import { formatDateWithTime, returnSign, formatDateBackend, formatMoney, formatDate } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()
	const { id } = useParams();

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		posId: null,
		cashierId: null,
		status: null,
		fromDate: null,
		toDate: null,
		amountFrom: '',
		amountTo: '',
		search: ''
	})
	const [pagination, setPagination] = useState({
		url: `/services/redeemapi/api/cashbox/cheque-user-pageList?userId=${id}`,
		response: null
	})
	const [data, setData] = useState([])
	const [modalDelete, setModalDelete] = useState({ 'bool': false, 'index': 0 })

	async function searchWithFilter(exportToExcel = false) {
		var filterUrl = `/services/redeemapi/api/cashbox/cheque-user-pageList?userId=${id}`
		var urlParams = ""
		checkFilter()
		
		if (filterData.fromDate)
			urlParams += returnSign(urlParams) + 'fromDate=' + filterData.fromDate
		if (filterData.toDate)
			urlParams += returnSign(urlParams) + 'toDate=' + filterData.toDate
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if (`/services/redeemapi/api/cashbox/cheque-user-pageList?userId=${id}` + urlParams !== filterUrl && !exportToExcel)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await PGET(filterUrl, {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response })
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			fromDate: null,
			toDate: null,
			search: ''
		})
		setFilterDataExist(false)
		getData()
	}

	async function getData() {
		const response = await PGET( `/services/redeemapi/api/cashbox/cheque-user-pageList?userId=${id}`, {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url':  `/services/redeemapi/api/cashbox/cheque-user-pageList?userId=${id}`, 'response': response })
		}
	}

	async function checkFilter() {
		if (
			filterData.fromDate === null &&
			filterData.toDate === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function deleteCheque() {
		await POST(`/services/redeemapi/api/cashbox/cheque-cancel`, {
			"id": data[modalDelete.index]['id'],
			"note": ""
		}, { loader: true })
		setModalDelete({ 'bool': false, 'index': 0 })
		searchWithFilter()
	}

	async function paginate(newPageData) {
		setData(newPageData)
	}

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<h4 className="vertical-center">
					{t('CHEQUES')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div>
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
						</div>
						<div>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('PERIOD')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.fromDate ? new Date(filterData.fromDate) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'fromDate': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label className="invisible">.</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.toDate ? new Date(filterData.toDate) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'toDate': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('APPLY')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{(filterData.fromDate || filterData.toDate) &&
									<span className="me-2">
										<b>{t('PERIOD')}: </b>
										{filterData.fromDate &&
											<span>{formatDate(filterData.fromDate)}</span>
										}
										{(filterData.fromDate && filterData.toDate) &&
											<span>|</span>
										}
										{filterData.toDate &&
											<span>{formatDate(filterData.toDate)}</span>
										}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-hover mb-0">
							<thead>
								<tr>
									<th>{t('DATE')}</th>
									<th className="text-center text-nowrap">{t('AMOUNT')}</th>
									<th className="text-center text-nowrap">{t('PAID_WITH_BALLS')}</th>
									<th className="text-center text-nowrap">{t('BONUS')}</th>
									<th className="text-center">{t('CLIENT')}</th>
									<th className="text-center">{t('CASHIER')}</th>
									<th className="text-center">{t('POS')}</th>
									<th className="text-center">{t('STATUS')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className={(item.showSidebar ? 'table-tr-active' : '')}
										key={index}>
										<td>{item.rowNum}. {formatDateWithTime(item.chequeDate)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.totalAmount)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.writeOff)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.bonus)}</td>
										<td className="text-center">{item.clientName}</td>
										<td className="text-center">{item.cashierName}</td>
										<td className="text-center">{item.posName}</td>
										<td className="d-flex justify-content-center">
											{item.debt ?
												<>
													<span className="bg-dark bg-border text-white">{t('DEBT')}</span>
												</>
												:
												<>
													{!item.cancel &&
														<span className="bg-success bg-border text-white">{t('SUCCESS')}</span>
													}
													{item.cancel &&
														<span className="bg-danger bg-border text-white">{t('RETURN')}</span>
													}
												</>
											}
										</td>
										<td>
											{!item.cancel &&
												<div className="d-flex justify-content-center">
													<div className="table-action-button table-action-danger-button"
														onClick={() => setModalDelete({ 'bool': true, 'index': index })}>
														<i className="uil-history-alt"></i>
													</div>
												</div>
											}
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			<Modal show={modalDelete.bool} animation={false}
				centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setModalDelete({ 'bool': false, 'index': 0 })}>
				<Modal.Header closeButton>
					<Modal.Title>{t('DELETE')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t('ARE_YOU_SURE_YOU_WANT_DELETE')}
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex w-100">
						<button className="btn btn-warning w-100 me-2" onClick={() => setModalDelete({ 'bool': false, 'index': 0 })}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary w-100" onClick={() => deleteCheque()}>{t('CONFIRM')}</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default Index
