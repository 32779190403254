import * as Yup from 'yup';

import { yup_ru } from 'locales/yup/yup_ru';
import { yup_uzCyrl } from 'locales/yup/yup_uzCyrl';
import { yup_uzLatn } from 'locales/yup/yup_uzLatn';

if (localStorage.getItem('I18N_LANGUAGE') === 'ru') {
	Yup.setLocale(yup_ru);
}
if (localStorage.getItem('I18N_LANGUAGE') === 'uz-Cyrl-UZ') {
	Yup.setLocale(yup_uzCyrl);
}
if (localStorage.getItem('I18N_LANGUAGE') === 'uz-Latn-UZ') {
	Yup.setLocale(yup_uzLatn);
}

export const validationSchema = Yup.object().shape({
	companyLogin: Yup.string()
		.required()
		.min(4),
	password: Yup.string()
		// .required()
		.nullable(),
		// .min(4),
	tin: Yup.string()
		.required()
		.length(9),
	name: Yup.string()
		.required(),
	phone: Yup.string()
		.required()
		.length(9),
	directorName: Yup.string()
		.required(),
	directorPhone: Yup.string()
		.required()
		.length(9),
	buxPhone: Yup.string()
		.length(9),
	regionId: Yup.string()
		.required()
		.nullable(),
	cityId: Yup.string()
		.required()
		.nullable(),
});