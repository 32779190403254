import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'


import { GET, PGET } from 'helpers/api'
import { formatMoney, returnSign, findFromArrayById, formatDateBackend, formatDate } from "helpers/helpers"

function Index() {

	const { t } = useTranslation()

	const [poses, setPoses] = useState([])
	const [data, setData] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		posId: null,
		ageFrom: null,
		ageTo: null,
		gender: null,
		search: ''
	})
	const [pagination, setPagination] = useState({
		url: '/services/redeemapi/api/cashbox/cheque-report',
		response: null
	})

	async function searchWithFilter() {
		var filterUrl = "/services/redeemapi/api/cashbox/cheque-report"
		var urlParams = ""
		checkFilter()

		if (filterData.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterData.posId
		if (filterData.chequeDate)
			urlParams += returnSign(urlParams) + 'chequeDate=' + filterData.chequeDate
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if ("/services/redeemapi/api/cashbox/cheque-report" + urlParams !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await PGET(filterUrl, {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response })
		}

		setDropdownIsOpen(false)
	}

	async function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.chequeDate === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function resetFilter() {
		setFilterData({
			posId: null,
			chequeDate: null,
			search: ''
		})
		setFilterDataExist(false)
		getData()
	}

	async function getData() {
		const response = await PGET('/services/redeemapi/api/cashbox/cheque-report', {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': '/services/redeemapi/api/cashbox/cheque-report', 'response': response })
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(newPageData) {
		setData(newPageData)
	}

	async function getPoses() {
		const response = await GET('/services/redeemapi/api/pos-cashier-helper')
		if (response) {
			setPoses(response)
		}
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('REPORT')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex mb-3">
						<div className="me-2">
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
						</div>
						<div>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('POSES')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.posId) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
										<div className="form-group col-md-6">
											<label>{t('DATE')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.chequeDate ? new Date(filterData.chequeDate) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'chequeDate': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button className="btn btn-primary" onClick={() => searchWithFilter()}>{t('APPLY')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
								{filterData.chequeDate &&
									<span className="me-2">
										<b>{t('DATE')}: </b>
										<span>{filterData.chequeDate}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>№ {t('POS')}</th>
									<th className="text-center">{t('DATE')}</th>
									<th className="text-center">{t('AMOUNT')}</th>
									<th className="text-center">{t('WRITE_OFF')}</th>
									<th className="text-center">{t('BONUS')}</th>
									<th className="text-end">{t('DEBT')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{item.rowNum} {item.posName}</td>
										<td className="text-center">{formatDate(item.chequeDate)}</td>
										<td className="text-center">{formatMoney(item.totalAmount)}</td>
										<td className="text-center">{formatMoney(item.writeOff)}</td>
										<td className="text-center">{formatMoney(item.bonus)}</td>
										<td className="text-end">{formatMoney(item.debtAmount)}</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{pagination.response &&
				<FilterPagination
					pagination={pagination}
					paginate={paginate}>
				</FilterPagination>
			}
		</>
	)
}

export default Index
