import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import { Modal } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'

import FilterSearch from 'pages/parts/FilterSearch'

import { PGET, GET, POST } from 'helpers/api'
import { formatDateWithTime, returnSign, formatDateBackend, formatDate, findFromArrayById } from 'helpers/helpers'


function Index() {
	const { t } = useTranslation()

	const [gasColumns, setGasColumns] = useState([])
	const [data, setData] = useState([])
	const [selectedItem, setSelectedItem] = useState({
		'counterDate': '',
		'counterList': []
	})
	const [showModal, setShowModal] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		posId: null,
		fromDate: null,
		toDate: null,
		search: ''
	})
	const [pagination, setPagination] = useState({
		url: '/services/redeemapi/api/gas-column-counter-pageList',
		response: null
	})

	async function createUpdate() {

		var selectedItemCopy = { ...selectedItem }

		var sendData = {
			'counterDate': selectedItemCopy.counterDate,
			'counterList': selectedItemCopy.counterList
		}
		var response = await POST('/services/redeemapi/api/gas-column-counter', sendData, { loader: true })

		if (response) {
			searchWithFilter()
			setShowModal(false)
		}
	}

	async function searchWithFilter() {
		var filterUrl = "/services/redeemapi/api/gas-column-counter-pageList"
		var urlParams = ""
		checkFilter()

		if (filterData.id)
			urlParams += returnSign(urlParams) + 'id=' + filterData.id
		if (filterData.fromDate)
			urlParams += returnSign(urlParams) + 'fromDate=' + filterData.fromDate
		if (filterData.toDate)
			urlParams += returnSign(urlParams) + 'toDate=' + filterData.toDate
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if ("/services/redeemapi/api/gas-column-counter-pageList" + urlParams !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await PGET(filterUrl, {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response })
		}

		setDropdownIsOpen(false)
	}

	function openModal() {
		setSelectedItem({
			'counterDate': new Date(),
			'counterList': gasColumns
		})
		setShowModal(true)
	}

	function closeModal() {
		setSelectedItem({
			'counterDate': '',
			'counterList': []
		})
		setShowModal(false)
	}

	function resetFilter() {
		setFilterData({
			posId: null,
			fromDate: null,
			toDate: null,
			search: ''
		})
		setFilterDataExist(false)
		getData()
	}

	async function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.fromDate === null &&
			filterData.toDate === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	function handleInputChange(value, index) {
		var selectedItemCopy = { ...selectedItem }
		selectedItemCopy.counterList[index]['counter'] = value
		setSelectedItem(selectedItemCopy)
	}

	async function getData() {
		const response = await PGET('/services/redeemapi/api/gas-column-counter-pageList', {}, { loader: true })
		if (response) {
			setData(response)
		}
	}

	async function getGasColumns() {
		var response = await GET('/services/redeemapi/api/gas-column-helper', {}, { loader: true })
		if (response) {
			setGasColumns(response)
			for (let i = 0; i < response.length; i++) {
				response[i]['counter'] = 0
			}
			setSelectedItem({ ...selectedItem, 'counterList': response })
		}
	}

	useEffect(() => {
		getData()
		getGasColumns()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('GAS_COLUMN')}
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary" onClick={() => openModal(true)}>
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div>
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
						</div>
						<div>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('GAS_COLUMNS')}</label>
											<Select
												options={gasColumns}
												value={gasColumns.find(option => option.id === filterData.id) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'id': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('PERIOD')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.fromDate ? new Date(filterData.fromDate) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'fromDate': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label className="invisible">.</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.toDate ? new Date(filterData.toDate) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'toDate': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('APPLY')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{(filterData.fromDate || filterData.toDate) &&
									<span className="me-2">
										<b>{t('PERIOD')}: </b>
										{filterData.fromDate &&
											<span>{formatDate(filterData.fromDate)}</span>
										}
										{(filterData.fromDate && filterData.toDate) &&
											<span>|</span>
										}
										{filterData.toDate &&
											<span>{formatDate(filterData.toDate)}</span>
										}
									</span>
								}
								{filterData.id &&
									<span className="me-2">
										<b>{t('GAS_COLUMNS')}: </b> {findFromArrayById(gasColumns, filterData.id)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('NAME3')}</th>
									<th className="text-center">{t('INDEX')}</th>
									<th className="text-center">{t('LAST_INDEX')}</th>
									<th className="text-center">{t('DIFFERENCE')}</th>
									<th className="text-center">{t('DATE')}</th>
									{/* <th className="text-center">{t('ACTION')}</th> */}
								</tr>
							</thead>
							<tbody>
								{data.length > 0 &&
									data.map((item, index) => (
										<tr key={index}>
											<td>{item.gasColumnName}</td>
											<td className="text-center">{item.counter}</td>
											<td className="text-center">{item.oldCounter}</td>
											<td className="text-center">{item.difference}</td>
											<td className="text-center">{formatDateWithTime(item.counterDate)}</td>
											{/* <td className="d-flex justify-content-center">
												<div className="table-action-button table-action-primary-button">
													<i className="uil-edit-alt"></i>
												</div>
											</td> */}
										</tr>
									))
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered size="lg" backdrop="static" onHide={() => closeModal()}>
				<Modal.Body>
					<div className="d-flex">
						<div className="form-group">
							<label>{t('DATE')}<span className="required-mark">*</span></label>
							<div className="calendar-input">
								<DatePicker
									className="form-control"
									dateFormat="dd.MM.yyyy"
									selected={selectedItem.counterDate ? new Date(selectedItem.counterDate) : ''}
									onChange={(date) => setSelectedItem({ ...selectedItem, 'counterDate': formatDateBackend(date) })} />
								<i className="uil uil-calendar-alt"></i>
							</div>
						</div>
					</div>
					<div className="table-responsive mb-3">
						<table className="table mb-0">
							<thead>
								<tr>
									<th>{t('NAME3')}</th>
									<th>{t('INDICATION')}</th>
									<th className="text-center">{t('LAST_INDICATION')}</th>
									<th className="text-center">{t('TOTAL')}</th>
								</tr>
							</thead>
							<tbody>
								{selectedItem.counterList.length > 0 &&
									selectedItem.counterList.map((item, index) => (
										<tr key={index}>
											<td>{item.name}</td>
											<td>
												<input type="text" className="auto-width-input"
													onChange={(e) => handleInputChange(e.target.value, index)} />
											</td>
											<td className="text-center">{item.lastCounter}</td>
											<td className="text-center">{item.counter > 0 ? item.counter - item.lastCounter : '0'}</td>
										</tr>
									))
								}
							</tbody>
						</table>
					</div>
					<div className="d-flex justify-content-end">
						<button className="btn btn-warning me-2" onClick={() => closeModal()}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary" onClick={() => createUpdate()}>{t('SAVE')}</button>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
