import React, { useState } from "react"
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'

import { GUESTPOST, GET } from 'helpers/api'
import { clearTemporaryStorage } from 'helpers/helpers'

import ru from 'assets/images/flags/ru.png'
import uzLatn from 'assets/images/flags/uz-Latn-UZ.png'
import uzCyrl from 'assets/images/flags/uz-Cyrl-UZ.png'
//import logo from 'assets/images/logosvg.svg'

function Login() {
	const { i18n, t } = useTranslation();
	const history = useHistory()

	const [user, setUser] = useState({ "username": "", "password": "" })
	const [showModal, setShowModal] = useState(false)
	const [poses, setPoses] = useState([])

	async function handleSubmit(e) {
		e.preventDefault();
		var sendData = {
			username: user.username.toLowerCase(),
			password: user.password
		}
		clearTemporaryStorage()
		const response = await GUESTPOST('/auth/login', sendData, { loader: true })
		if (response) {
			if (!response.activated) {
				toast.error(t('ERROR') + ': ' + t('YOUR_ACCOUNT_BLOCKED'))
				return
			}

			localStorage.setItem("username", user.username.toLowerCase());
			localStorage.setItem('access_token', response.access_token)
			localStorage.setItem("tokenTime", JSON.stringify(new Date().getTime()));
			localStorage.setItem("authUser", JSON.stringify(user))

			const account = await GET('/services/uaa/api/account')
			localStorage.setItem("authorities", JSON.stringify(account.authorities));

			var checker = false
			for (let i = 0; i < account.authorities.length; i++) {
				if (account.authorities[i] === "ROLE_BUSINESS_OWNER" || account.authorities[i] === "ROLE_CASHIER" || account.authorities[i] === "ROLE_DIRECTOR" || account.authorities[i] === "ROLE_COMPANY") {
					var role = ""
					if (account.authorities[i] === "ROLE_DIRECTOR") {
						role = "ROLE_DIRECTOR"
					} else {
						role = "ROLE_BUSINESS_OWNER"
					}

					checker = true
					if (account.authorities[i] === "ROLE_CASHIER")
						role = "ROLE_CASHIER"
					checker = true
					if (account.authorities[i] === "ROLE_COMPANY")
						role = "ROLE_COMPANY"

					localStorage.setItem("role", role);
				}
			}

			if (checker === true) {
				if (role && role === "ROLE_CASHIER") {
					const accessPoses = await GET('/services/redeemapi/api/get-access-pos')
					if (accessPoses.length === 1) {
						localStorage.setItem('pos_id', accessPoses[0]['posId'])
						history.push("/cashier-cashbox")
					} else {
						setPoses(accessPoses)
						setShowModal(true)
					}
				} else if (role && role === "ROLE_COMPANY") {
					history.push("/my-company/company")
				} else {
					history.push("/dashboard")
				}
			}
		}
	}

	function selectPos(item) {
		localStorage.setItem('pos_id', item.posId)
		history.push("/cashier-cashbox")
	}

	function changeLanguage(language = 'uz-Latn-UZ') {
		i18n.changeLanguage(language);

		if (language === 'ru') {
			localStorage.setItem('lang', 'ru');
		}
		if (language === 'uz-Latn-UZ') {
			localStorage.setItem('lang', 'uz-Latn-UZ');
		}
		if (language === 'uz-Cyrl-UZ') {
			localStorage.setItem('lang', 'uz-Cyrl-UZ');
		}
	};

	function getCurrentLocale() {
		const locale = i18n.language
		if (locale === 'ru') return { flag: ru, lang: 'RUSSIAN' }
		else if (locale === 'uz-Latn-UZ') return { flag: uzLatn, lang: 'UZBEK_LATN' }
		else if (locale === 'uz-Cyrl-UZ') return { flag: uzCyrl, lang: 'UZBEK_CYRL' }
	}

	const LanguageToggle = React.forwardRef(({ children, onClick }, ref) => (
		<span ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}>
			{children}
		</span>
	));

	return (
		<>
			<div className="authentication-bg">
				<div className="account-pages h-100 vertical-center">

					<div className="d-flex justify-content-center">
						<div className="auth-card p-4 position-relative">

							<div className="position-absolute lang-position">
								<Dropdown className="cursor-pointer d-flex cursor">
									<Dropdown.Toggle as={LanguageToggle}>
										<div className="d-flex">
											<div className="vertical-center">
												<img src={getCurrentLocale().flag} className="me-2" alt="ru" width="24" height="16" />
											</div>
											<span>{t(getCurrentLocale().lang)}</span>
										</div>
									</Dropdown.Toggle>

									<Dropdown.Menu>
										{i18n.language !== 'ru' &&
											<Dropdown.Item onClick={() => changeLanguage("ru")}>
												<div className="d-flex">
													<div className="vertical-center"><img src={ru} className="me-2" alt="ru" width="24" height="16" /></div>
													<span>{t('RUSSIAN')}</span>
												</div>
											</Dropdown.Item>
										}
										{i18n.language !== 'uz-Latn-UZ' &&
											<Dropdown.Item onClick={() => changeLanguage("uz-Latn-UZ")}>
												<div className="d-flex">
													<div className="vertical-center"><img src={uzLatn} className="me-2" alt="uz-Latn-UZ" width="24" height="16" /></div>
													<span>{t('UZBEK_LATN')}</span>
												</div>
											</Dropdown.Item>
										}
										{i18n.language !== 'uz-Cyrl-UZ' &&
											<Dropdown.Item onClick={() => changeLanguage("uz-Cyrl-UZ")}>
												<div className="d-flex">
													<div className="vertical-center"><img src={uzCyrl} className="me-2" alt="uz-Cyrl-UZ" width="24" height="16" /></div>
													<span>{t('UZBEK_CYRL')}</span>
												</div>
											</Dropdown.Item>
										}
									</Dropdown.Menu>
								</Dropdown>
							</div>

							<div className="p-2">
								{/* <div className="d-flex justify-content-center mb-3">
									<img src={logo} alt="logo" width={200} />
								</div> */}
								<form className="form-horizontal" autoComplete="off" onSubmit={handleSubmit}>

									<div className="d-flex mb-2 justify-content-center">
										<h3>Вход в систему</h3>
									</div>
									<p className="text-center mb-2">Добро пожаловать. Пожалуйста, войдите в свой аккаунт</p>

									<div className="form-group">
										<label className="form-label">{t('LOGIN')}<span className="required-mark">*</span></label>
										<input className="form-control"
											autoFocus
											name="username"
											value={user.username}
											onChange={(e) => setUser({ ...user, 'username': e.target.value })} />
									</div>

									<div className="form-group mb-4">
										<label className="form-label">{t('PASSWORD')}<span className="required-mark">*</span></label>
										<input className="form-control"
											type="password"
											name="password"
											value={user.password}
											onChange={(e) => setUser({ ...user, 'password': e.target.value })} />
									</div>

									<div className="text-center">
										<button type="submit" className="w-100 btn btn-primary">
											{t('ENTER')}
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" onHide={() => setShowModal(false)}>
				<Modal.Body>
					<h3 className="text-center mb-3">Выберите торговую точку</h3>
					{poses.map((item, index) => (
						<button className="btn btn-primary w-100 mb-3 py-3" key={index} onClick={() => selectPos(item)}>
							<span className="text-center">{item.posName}</span>
						</button>
					))
					}
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Login;
