export let exportMenuCashier = [
	{
		url: "/cashier-cashbox",
		i18n: "CASHBOX",
		icon: "uil-home-alt",
		active: false,
	},
	{
		url: "/cashier-cheques",
		i18n: "CHEQUES",
		icon: "uil-invoice",
		active: false,
	},
	{
		url: "/cashier-clients",
		i18n: "CLIENTS",
		icon: "uil-users-alt",
		active: false,
	},
	{
		url: "/cashier-gas-columns",
		i18n: "GAS_COLUMN",
		icon: "uil-fire",
		active: false,
	},
	{
		url: "/cashier-expenses",
		i18n: "EXPENSES",
		icon: "uil-money-insert",
		active: false,
	},
	{
		url: "/x-report",
		i18n: "REPORT",
		icon: "uil-chart-pie-alt",
		active: false,
	},
]