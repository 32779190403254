import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import { GET } from "helpers/api";
import { formatDate, formatMoney, regexPhoneNumber } from 'helpers/helpers'

function Detail() {
	const { t } = useTranslation()
	const { posId, userId } = useParams();

	const [client, setClient] = useState({})
	const [data, setData] = useState([])

	async function getData() {
		const response = await GET(`/services/web/api/user-debt/${posId}/${userId}`, {}, { loader: true })
		setClient(response)

		const response2 = await GET(`/services/web/api/user-debt-history-pageList?posId=${posId}&userId=${userId}`, {}, { loader: true })
		setData(response2)
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('CLIENT')}
				</h4>
			</div>

			<div className="card mb-3">
				<div className="card-body">
					<div className="row">
						<div className="col-md-5">
							<div className="d-flex justify-content-between mb-3">
								<div>
									<i className="uil uil-user me-3"></i>
									<span>{t('CLIENT')}</span>
								</div>
								<div>{client.name}</div>
							</div>
							<div className="d-flex justify-content-between mb-3">
								<div>
									<i className="uil uil-mobile-android-alt me-3"></i>
									<span>{t('PHONE_NUMBER')}</span>
								</div>
								<div>{regexPhoneNumber(client.login)}</div>
							</div>
							<div className="d-flex justify-content-between mb-3">
								<div>
									<i className="uil uil-dialpad-alt me-3"></i>
									<span>{t('CAR_NUMBER')}</span>
								</div>
								<div>{client.carNumber}</div>
							</div>
							<div className="d-flex justify-content-between mb-3">
								<div>
									<i className="uil uil-car me-3"></i>
									<span>{t('CAR_TYPE')}</span>
								</div>
								<div>{client.carTypeName}</div>
							</div>
							<div className="d-flex justify-content-between mb-3">
								<div>
									<i className="uil uil-airplay me-3"></i>
									<span>{t('STATUS')}</span>
								</div>
								<div>{client.status}</div>
							</div>
							<div className="d-flex justify-content-between mb-3">
								<div>
									<i className="uil uil-award me-3"></i>
									<span>{t('BONUS')}</span>
								</div>
								{client.award &&
									<div>{client.award}%</div>
								}
							</div>
							<div className="d-flex justify-content-between mb-3">
								<div>
									<i className="uil uil-calender me-3"></i>
									<span>{t('LAST_ACTIVITY')}</span>
								</div>
								<div>{formatDate(client.lastChequeDate)}</div>
							</div>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-5">
							<div className="d-flex justify-content-between mb-3">
								<div>
									<i className="uil uil-award-alt me-3"></i>
									<span>{t('BALANCE')}</span>
								</div>
								<div>{formatMoney(client.balance, 0)}</div>
							</div>
							{client.debtAmount > 0 &&
								<div>
									<div className="d-flex justify-content-between mb-3">
										<div>
											<i className="uil uil-diamond me-3"></i>
											<span>{t('DEBT')}</span>
										</div>
										<div>{client.debtAmount}</div>
									</div>
									{client.returnDebtDate &&
										<div className="d-flex justify-content-between mb-3">
											<div>
												<i className="uil uil-airplay me-3"></i>
												<span>{t('RETURN_DATE')}</span>
											</div>
											<div>{formatDate(client.returnDebtDate)}</div>
										</div>
									}
								</div>
							}
							<div className="d-flex justify-content-between mb-3">
								<div>
									<i className="uil uil-game-structure me-3"></i>
									<span>{t('STATUS')}</span>
								</div>
								<div>{client.balanceStatus}</div>
							</div>
							<div className="d-flex justify-content-between mb-3">
								<div>
									<i className="uil uil-globe me-3"></i>
									<span>{t('CITY')}</span>
								</div>
								<div>{client.cityName}</div>
							</div>
							<div className="d-flex justify-content-between mb-3">
								<div>
									<i className="uil uil-globe me-3"></i>
									<span>{t('REGION')}</span>
								</div>
								<div>{client.regionName}</div>
							</div>
							<div className="d-flex justify-content-between mb-3">
								<div>
									<i className="uil uil-credit-card me-3"></i>
									<span>{t('CARD_NUMBER')}</span>
								</div>
								<div>{client.cardNumber}</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>№ {t('CASHIER')}</th>
									<th className="text-center">{t('PAID')}</th>
									<th className="text-center">{t('DEBT')}</th>
									<th className="text-center">{t('DATE')}</th>
								</tr>
							</thead>
							<tbody>
								{data.length > 0 &&
									data.map((item, index) => (
										<tr key={index}>
											<td>{item.rowNum}. {item.cashierName}</td>
											<td className="text-center">{formatMoney(item.debtIn)}</td>
											<td className="text-center">{formatMoney(item.debtOut)}</td>
											<td className="text-center">{formatDate(item.createdDate)}</td>
										</tr>
									))
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	)
}

export default Detail