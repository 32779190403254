import React, { useEffect, useState } from "react"
import { Link, useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Select from 'react-select'

import { GET, POST, PUT } from 'helpers/api'

function CreateUpdate() {
	const { t } = useTranslation()
	const { id } = useParams();
	const history = useHistory();

	const [carTypes, setCarTypes] = useState([])
	const [data, setData] = useState({
		'rulesList': [
			//{ clientStatusName: "Base", award: "1", fromAmount: "0", showInput: false }
		],
		'posList': [],
		'name': "",
	})
	const [earnPointStatusRule, setEarnPointStatusRule] = useState({
		'clientStatusName': '',
		'award': '',
		'fromAmount': '',
		'showInput': false
	})
	const [poses, setPoses] = useState([])

	async function createUpdate() {
		var dataCopy = { ...data }

		var response
		if (!id) {
			if (dataCopy.posList.length === 0) {
				toast.error(t('ADD_MINUMUM_PLACE_OF_IMPLEMENTATION'))
				return;
			}
			if (dataCopy.rulesList.length === 0) {
				toast.error(t('ADD_MINUMUM_RULE'))
				return;
			}

			response = await POST('/services/web/api/cash-back', dataCopy)
		} else {
			response = await PUT('/services/web/api/cash-back', dataCopy)
		}

		if (response) {
			history.push('/services')
		}
	}

	function addEarnPointStatusRule() {
		//var checkForEquality = 0
		var dataCopy = { ...data }
		// for (let i = 0; i < dataCopy.rulesList.length; i++) {
		// 	if (earnPointStatusRule.fromAmount === dataCopy.rulesList[i]['fromAmount']) {
		// 		checkForEquality = 1
		// 	}
		// 	if (earnPointStatusRule.award === dataCopy.rulesList[i]['award']) {
		// 		checkForEquality = 2
		// 	}
		// }

		// if (checkForEquality === 1) {
		// 	toast.error(t('RULE_SAME_SCORE_EXIST'))
		// 	return
		// }

		// if (checkForEquality === 2) {
		// 	toast.error(t('RULE_SAME_AWARD_EXIST'))
		// 	return
		// }

		dataCopy.rulesList.push(earnPointStatusRule)
		setData(dataCopy)
		setEarnPointStatusRule({
			'clientStatusName': '',
			'award': '',
			'fromAmount': '',
			'showInput': false
		})
	}

	function handleSelectPos(options) {
		setData({ ...data, 'posList': options })
	}

	function handleSelectCarType(option, index) {
		var dataCopy = JSON.parse(JSON.stringify(data))
		dataCopy['rulesList'][index]['carTypeId'] = option.id
		dataCopy['rulesList'][index]['carTypeName'] = option.name

		setData(dataCopy)
	}

	async function getData(bool = false) {
		const response = await GET('/services/web/api/cash-back/' + id, {}, { loader: true })
		if (response) {
			for (let i = 0; i < response.rulesList.length; i++) {
				response.rulesList[i]['showInput'] = false
			}
			setData(response)
			//setPoses(response.posList)
		}
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-cash-back-helper', {}, { loader: true })
		if (response) {
			for (let i = 0; i < response.length; i++) {
				response[i]['posName'] = response[i]['name']
				response[i]['posId'] = response[i]['id']
			}
			setPoses(response)
		}
	}

	function deleteRule(index) {
		var dataCopy = { ...data }
		dataCopy.rulesList.splice(index, 1)
		setData(dataCopy)
	}

	async function getCarTypes() {
		const response = await GET('/services/mobile/api/car-type-helper')
		setCarTypes(response)
	}

	useEffect(() => {
		if (id) {
			getData()
		}
		getCarTypes()
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<h4 className="vertical-center">
					{t('CREATE')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<h4>
						<strong>{t('REGULATIONS')}</strong>
					</h4>
					<hr />

					<div className="form-group w-25 mb-3">
						<label>{t('NAME3')}<span className="required-mark">*</span></label>
						<input type="text" className="form-control"
							value={data.name}
							onChange={(e) => setData({ ...data, 'name': e.target.value })} />
					</div>

					<div className="row">
						<div className="col-md-8">
							<div className="table-responsive">
								<table className="table table-striped mb-0">
									<thead>
										<tr>
											<th>{t('CAR_TYPE')}</th>
											<th>{t('CUSTOMER_STATUSES')}</th>
											<th>{t('BONUS_PERCENTAGE')}</th>
											<th>{t('POINTS_MORE')}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className="w-25">
												<div >
													<Select
														menuPosition="fixed"
														options={carTypes}
														value={carTypes.find(option => option.id === earnPointStatusRule.carTypeId) || ''}
														onChange={(option) => setEarnPointStatusRule({
															...earnPointStatusRule, 'carTypeId': option.id, 'carTypeName': option.name
														})}
														placeholder=""
														noOptionsMessage={() => t('LIST_IS_EMPTY')}
														getOptionLabel={(option) => option.name}
														getOptionValue={(option) => option.id}
													/>
												</div>
											</td>
											<td>
												<input type="text" maxLength="20" className="form-control"
													value={earnPointStatusRule.clientStatusName}
													onChange={(e) => setEarnPointStatusRule({ ...earnPointStatusRule, 'clientStatusName': e.target.value })} />
											</td>
											<td>
												<input type="text" pattern="[0-9]*" className="form-control"
													value={earnPointStatusRule.award}
													onChange={(e) => setEarnPointStatusRule({ ...earnPointStatusRule, 'award': e.target.value.replace(/[^0-9.]/g, '') })} />
											</td>
											<td>
												<input type="text" pattern="[0-9]*" className="form-control"
													value={earnPointStatusRule.fromAmount}
													onChange={(e) => setEarnPointStatusRule({ ...earnPointStatusRule, 'fromAmount': e.target.value.replace(/[^0-9.]/g, '') })} />
											</td>
											<td>
												<button className="btn btn-primary" onClick={() => addEarnPointStatusRule()}
													disabled={!earnPointStatusRule.award || !earnPointStatusRule.clientStatusName || !earnPointStatusRule.fromAmount}>
													{t('ADD')}
												</button>
											</td>
										</tr>
										{data.rulesList.map((item, index) => (
											<tr key={index}>
												<td>
													{item.showInput ?
														<div >
															<Select
																menuPosition="fixed"
																options={carTypes}
																value={carTypes.find(option => option.id === item.carTypeId) || ''}
																onChange={(option) => handleSelectCarType(option, index)}
																placeholder=""
																noOptionsMessage={() => t('LIST_IS_EMPTY')}
																getOptionLabel={(option) => option.name}
																getOptionValue={(option) => option.id}
															/>
														</div>
														:
														<span>{item.carTypeName}</span>
													}
												</td>
												<td>
													<span>{item.clientStatusName}</span>
												</td>
												<td>
													{item.showInput ?
														<input type="text" pattern="[0-9]*" className="form-control"
															value={data.rulesList[0]['award']}
															onChange={(e) => {
																var dataCopy = { ...data }
																dataCopy.rulesList[0]['award'] = e.target.value.replace(/[^0.1-9.]/g, '')
																setData(dataCopy)
															}}
														/>
														:
														<span>{item.award}%</span>
													}
												</td>
												<td>
													<span>{item.fromAmount}</span>
												</td>
												<td className="d-flex justify-content-center">
													<div className="table-action-button table-action-danger-button me-2" onClick={() => deleteRule(index)}>
														<i className="uil-trash-alt"></i>
													</div>
												</td>
											</tr>
										))
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<h4 className="mt-3 mb-3">
						<strong>{t('PLACES_OF_IMPLEMENTATION')}</strong>
					</h4>

					<div className="form-group">
						<label>{t('POSES')}</label>
						<Select
							value={data.posList}
							options={poses}
							onChange={(options) => handleSelectPos(options)}
							isMulti
							placeholder=""
							noOptionsMessage={() => t('LIST_IS_EMPTY')}
							getOptionLabel={(option) => option.posName}
							getOptionValue={(option) => option.id}
						/>
					</div>

					<div className="btn-wrapper">
						<Link type="button" className="btn btn-warning btn-rounded btn-wide me-2" to="/services">{t('CANCEL')}</Link>
						<button type="button" className="btn btn-primary btn-rounded btn-wide btn-wide"
							onClick={() => createUpdate()}
							disabled={!(data.name)}>
							{t('SAVE')}
						</button>
					</div>

				</div>
			</div>
		</>
	)
}
export default CreateUpdate