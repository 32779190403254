import React, { useEffect, useState, useRef } from "react"
import { Link, useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import Select from 'react-select'

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation'

import { GET, POST, PUT } from 'helpers/api'
import { toast } from "react-toastify";

function CreateUpdate() {
	const { t } = useTranslation();
	const { id } = useParams();
	const history = useHistory();

	const ymaps = useRef(null);

	const { register, handleSubmit, formState, control, setValue, getValues } = useForm({
		mode: "onChange",
		resolver: yupResolver(validationSchema),
		defaultValues: {
			address: "",
			phone: "",
			regionId: 10,
			cityId: "",
			gpsPointX: 41.311081,
			gpsPointY: 69.240562,
			password: ""
		}
	});
	const { errors } = formState

	const [isDisabled, setIsDisabled] = useState(false)
	const [regions] = useState([{ "id": 1, "name": "Андижанская область" }, { "id": 2, "name": "Бухарская область" }, { "id": 3, "name": "Джизакская область" }, { "id": 4, "name": "Кашкадарьинская область" }, { "id": 5, "name": "Навоийская область" }, { "id": 6, "name": "Наманганская область" }, { "id": 7, "name": "Самаркандская область" }, { "id": 8, "name": "Сурхандарьинская область" }, { "id": 9, "name": "Сырдарьинская область" }, { "id": 10, "name": "город Ташкент" }, { "id": 11, "name": "Ташкентская область" }, { "id": 12, "name": "Ферганская область" }, { "id": 13, "name": "Хорезмская область" }, { "id": 14, "name": "Респ. Каракалпакстан" }])
	const [cities, setCities] = useState([])
	const [data, setData] = useState({
		address: "",
		phone: "",
		regionId: 10,
		cityId: "",
		gpsPointX: 41.311081,
		gpsPointY: 69.240562,
	})
	const [defaultState, setDefaultState] = useState({
		center: [41.311081, 69.240562],
		zoom: 11,
		controls: []
	});

	async function checkLogin(value) {
		const response = await GET(`/services/web/api/check-login?login=${value}`)
		return response?.success
	}

	async function searchByTin() {
		const response = await GET(`/services/web/api/company-search-tin?tin=${getValues('tin')}`)
		if (response?.id) {
			setIsDisabled(true)
			for (var key in response) {
				setValue(key, response[key])
			}
			setValue('password', '')
			setValue('phone', getValues('phone').substring(3, 12))
			setValue('directorPhone', getValues('directorPhone').substring(3, 12))
			setValue('buxPhone', getValues('buxPhone').substring(3, 12))
			toast.success(t('SUCCESS'))
		} else {
			for (key in getValues()) {
				setValue(key, '')
			}
			toast.error(t('ERROR'))
		}
	}

	async function createUpdate(validatedData) {
		var sendData = { ...validatedData }
		console.log(!id);
		if (!id) {
			var loginExist = await checkLogin(sendData.companyLogin)
			if (!loginExist) {
				return;
			}
		}
		sendData.gpsPointX = data.gpsPointX
		sendData.gpsPointY = data.gpsPointY
		sendData.cashierList = data.cashierList
		sendData.directorList = data.directorList
		if (!sendData.balance) {
			sendData.balance = 0
		}
		sendData.phone = '998' + sendData.phone
		sendData.directorPhone = '998' + sendData.directorPhone
		sendData.buxPhone = '998' + sendData.buxPhone
		var response;
		if (sendData.id) {
			response = await PUT('/services/web/api/company', sendData, { loader: true })
			if (response) {
				history.goBack()
			}
		} else {
			response = await POST('/services/web/api/company', sendData, { loader: true })
			if (response) {
				history.goBack()
			}
		}
	}

	async function selectRegion(option) {
		setValue('regionId', option.id)
		setValue('cityId', "")
		const response = await GET('/services/web/api/city-helper/' + option.id)
		if (response.length > 0) {
			setCities(response)
		}
	}

	async function getData() {
		const response = await GET('/services/web/api/company/' + id, {}, { loader: true })

		const temporaryCities = await GET('/services/web/api/city-helper/' + response.regionId)
		setCities(temporaryCities)

		for (const [key, value] of Object.entries(response)) {
			setValue(key, value)
		}
		setValue('password', '')
		setValue('phone', getValues('phone').substring(3, 12))
		setValue('directorPhone', getValues('directorPhone').substring(3, 12))
		setValue('buxPhone', getValues('buxPhone').substring(3, 12))
		setData({
			...data,
			'cashierList': response.cashierList,
			'directorList': response.directorList,
			'gpsPointX': response.gpsPointX ?? 41.311081,
			'gpsPointY': response.gpsPointY ?? 69.240562
		})
		setDefaultState({ ...defaultState, center: [response.gpsPointX ?? 41.311081, response.gpsPointY ?? 69.240562] })
	}

	async function getCities() {
		const response = await GET('/services/web/api/city-helper/' + getValues('regionId'))
		if (response.length > 0) {
			setCities(response)
		}
	}

	function checkPhone(e) {
		if (e.target.value > 9) {
			setValue(e.target.name, e.target.value.substring(0, 9))
		}
	}

	useEffect(() => {
		if (id) {
			setIsDisabled(true)
			getData()
		} else {
			getCities()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<div className="d-flex">
					<h4 className="vertical-center">
						{t('COMPANY')} / {id ? t('EDIT') : t('CREATE')}
					</h4>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<form onSubmit={handleSubmit(createUpdate)} autoComplete="off">
						<div className="row mb-4">
							<div className="col-md-3">
								<div className="form-group">
									<div className="form-group w-100">
										<label>{t('TIN')}<span className="required-mark">*</span></label>
										<div className="d-flex align-items-center justify-content-between">
											<input type="number" className="form-control me-3" name="tin" {...register('tin')} disabled={id} />
											<button type="button" className="btn btn-primary w-50" onClick={() => searchByTin()}
												disabled={id || getValues('gtin')?.length === 9}>
												{t('SEARCH')}
												<i className="uil uil-search ms-1"></i>
											</button>
										</div>
										<span className="text-danger text-sm">{errors.tin?.message}</span>
									</div>
									<label>{t('LOGIN')}<span className="required-mark">*</span></label>
									<input type="text" className="form-control" name="companyLogin" {...register('companyLogin')}
										disabled={isDisabled} />
									<span className="text-danger text-sm">{errors.companyLogin?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('PASSWORD')}<span className="required-mark">*</span></label>
									<input type="password" className="form-control" name="password"  {...register('password')}
										disabled={isDisabled} />
									<span className="text-danger text-sm">{errors.password?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('COMPANY_NAME')}<span className="required-mark">*</span></label>
									<input type="text" className="form-control" name="name" {...register('name')} />
									<span className="text-danger text-sm">{errors.name?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('PHONE')}<span className="required-mark">*</span></label>
									<div className="input-group">
										<span className="input-group-text">998</span>
										<input type="number" className="form-control" name="phone" {...register('phone', {
											onChange: (e) => {
												checkPhone(e)
											},
										})} />
									</div>
									<span className="text-danger text-sm">{errors.phone?.message}</span>
								</div>
							</div>
							<div className="col-md-3">
								<div className="form-group">
									<label>{t('DIRECTOR_NAME')}<span className="required-mark">*</span></label>
									<input type="text" className="form-control" name="directorName" {...register('directorName')} />
									<span className="text-danger text-sm">{errors.directorName?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('DIRECTOR_PHONE')}<span className="required-mark">*</span></label>
									<div className="input-group">
										<span className="input-group-text">998</span>
										<input type="number" className="form-control" name="directorPhone" {...register('directorPhone', {
											onChange: (e) => {
												checkPhone(e)
											},
										})} />
									</div>
									<span className="text-danger text-sm">{errors.directorPhone?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('ACCOUNTANT_NAME')}</label>
									<input type="text" className="form-control" name="buxName" {...register('buxName')} />
									<span className="text-danger text-sm">{errors.buxName?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('ACCOUNTANT_PHONE')}</label>
									<div className="input-group">
										<span className="input-group-text">998</span>
										<input type="number" className="form-control" name="buxPhone" {...register('buxPhone', {
											onChange: (e) => {
												checkPhone(e)
											},
										})} />
									</div>
									<span className="text-danger text-sm">{errors.buxPhone?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('BALANCE')}</label>
									<input type="number" className="form-control" name="balance" {...register('balance', {
										onChange: (e) => {
											if (Number(e.target.value) >= 1000000000) {
												setValue('balance', e.target.value.substring(0, e.target.value.length - 1))
											}
											if (Number(e.target.value) <= 0) {
												setValue('balance', '')
											}
										},
									})} />
									<span className="text-danger text-sm">{errors.balance?.message}</span>
								</div>
							</div>
							<div className="col-md-3">
								<YMaps
									style={{ height: '250px' }}
									query={{
										load: "package.full",
										apikey: 'e3f34c52-04d9-4fc6-aa00-29de77883de7'
									}}
								>
									<Map
										defaultOptions={{
											copyrightUaVisible: false,
											yandexMapDisablePoiInteractivity: true,
										}}
										style={{ height: '250px' }}
										state={defaultState}
										onLoad={(e) => {
											ymaps.current = e;
										}}
										onClick={(event) => {
											const coords = event.get("coords");
											ymaps.current.geocode(coords).then(() => {
												setData({ ...data, 'gpsPointX': coords[0], 'gpsPointY': coords[1] })
											});
										}}>
										<Placemark geometry={[data.gpsPointX, data.gpsPointY]} />
									</Map>
								</YMaps>
							</div>
							<div className="col-md-3">
								<div className="form-group">
									<label>{t('ADDRESS')}</label>
									<input type="text" className="form-control" name="address" {...register('address')} />
									<span className="text-danger text-sm">{errors.address?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('REGION')}<span className="required-mark">*</span></label>
									<Controller
										name="regionId"
										control={control}
										render={({ field }) =>
											<Select
												{...field}
												options={regions}
												value={regions.find(option => option.id === getValues('regionId')) || ''}
												onChange={(option => selectRegion(option))}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>}
									/>
									<span className="text-danger text-sm">{errors?.regionId?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('CITY')}<span className="required-mark">*</span></label>
									<Controller
										name="cityId"
										control={control}
										render={({ field }) =>
											<Select
												{...field}
												options={cities}
												value={cities.find(option => option.id === getValues('cityId')) || ''}
												onChange={(e) => setValue('cityId', e.id)}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>}
									/>
								</div>
								<span className="text-danger text-sm">{errors?.cityId?.message}</span>
							</div>
						</div>

						<div className="btn-wrapper">
							<Link type="button" className="btn btn-warning btn-rounded btn-wide me-2" to="/company">{t('CANCEL')}</Link>
							<button type="submit" className="btn btn-primary btn-rounded btn-wide btn-wide">{t('SAVE')}</button>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

export default CreateUpdate

