import * as Yup from 'yup';

import { yup_ru } from 'locales/yup/yup_ru';
import { yup_uzCyrl } from 'locales/yup/yup_uzCyrl';
import { yup_uzLatn } from 'locales/yup/yup_uzLatn';

if (localStorage.getItem('I18N_LANGUAGE') === 'ru') {
	Yup.setLocale(yup_ru);
}
if (localStorage.getItem('I18N_LANGUAGE') === 'uz-Cyrl-UZ') {
	Yup.setLocale(yup_uzCyrl);
}
if (localStorage.getItem('I18N_LANGUAGE') === 'uz-Latn-UZ') {
	Yup.setLocale(yup_uzLatn);
}

export const validationSchema = Yup.object().shape({
	name: Yup.string()
		.required(),
	login: Yup.string()
		//.matches(/^[aA-zZ0-9\s]+$/, "Invalid")
		.required(),
	// password: Yup.string()
	// 	.nullable(),
});