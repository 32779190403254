import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'

import Pagination from 'pages/parts/Pagination'

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation'

import { GET, POST, PUT, PGET } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const settings = useSelector(state => state.settings)

	const { register, handleSubmit, formState, control, setValue, getValues, reset } = useForm({
		mode: "onChange",
		resolver: yupResolver(validationSchema),
	});
	const { errors } = formState

	const gasColumnTypes = [
		{ 'id': 2, 'name': t('NEW') },
		{ 'id': 1, 'name': t('OLD') },
	]
	const [poses, setPoses] = useState([])
	const [posId, setPosId] = useState(0)
	const [data, setData] = useState([])
	const [selectedItem, setSelectedItem] = useState({})
	const [showModal, setShowModal] = useState(false)
	const [pagination] = useState({
		url: '/services/web/api/gas-column-pageList',
		response: null
	})
	const [pageCount, setPageCount] = useState(0)

	async function createUpdate(validatedData) {
		var selectedItemCopy = { ...validatedData }
		var response;
		if (selectedItemCopy.id) {
			response = await PUT('/services/web/api/gas-column', selectedItemCopy, { loader: true })
		} else {
			response = await POST('/services/web/api/gas-column', selectedItemCopy, { loader: true })
		}

		if (response) {
			getData()
			setShowModal(false)
		}
	}

	function openModal(item) {
		var itemCopy = { ...item }
		for (const [key, value] of Object.entries(itemCopy)) {
			setValue(key, value)
		}
		setSelectedItem(itemCopy)
		setShowModal(true)
	}

	function closeModal() {
		setShowModal(false)
		reset()
	}

	async function select(option, type) {
		if (type === 'posId') {
			setValue('posId', option.id)
		}
		if (type === 'gasColumnType') {
			setValue('gasColumnType', option.id)
		}
	}

	async function getData(posId) {
		var filterUrl = ''
		if (posId) {
			setPosId(posId)
			filterUrl = `?posId=${posId}`
		}

		const response = await PGET(`/services/web/api/gas-column-pageList${filterUrl}`, {}, { loader: true })
		if (response) {
			for (const [key, value] of Object.entries(response.data)) {
				setValue(key, value)
			}
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	async function paginate(data) {
		const response = await PGET(pagination.url, { page: data.selected, size: settings.size })
		setData(response.data);
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)
		}
	}

	useEffect(() => {
		getData()
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('GAS_COLUMN')}
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary" onClick={() => {
						setSelectedItem({})
						setShowModal(true)
					}}>
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex mb-3">
						<div style={{ 'width': '207px' }}>
							<Select
								options={poses}
								value={poses.find(option => option.id === posId) || ''}
								onChange={(option) => getData(option.id)}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('NAME3')}</th>
									<th>{t('POS')}</th>
									<th>{t('TYPE')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{item.name}</td>
										<td>{item.posName}</td>
										<td>
											{item.gasColumnType === 1 &&
												<span>{t('OLD')}</span>
											}
											{item.gasColumnType === 2 &&
												<span>{t('NEW')}</span>
											}
										</td>
										<td className="d-flex justify-content-center">
											<div className="table-action-button table-action-primary-button" onClick={() => openModal(item)}>
												<i className="uil-edit-alt"></i>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<Pagination pageCount={pageCount} paginate={paginate}></Pagination>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedItem.id ?
							<>{t('EDIT')}</>
							:
							<>{t('CREATE')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(createUpdate)} autoComplete="off">
						<div className="form-group">
							<label>{t('NAME3')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="name" {...register('name')} />
							<span className="text-danger text-sm">{errors.name?.message}</span>
						</div>
						<div className="form-group">
							<label>{t('TYPE')}<span className="required-mark">*</span></label>
							<Controller
								name="gasColumnType"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										menuPosition="absolute"
										options={gasColumnTypes}
										value={gasColumnTypes.find(option => option.id === getValues('gasColumnType') || '')}
										onChange={(option) => select(option, 'gasColumnType')}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>}
							/>
							<span className="text-danger text-sm">{errors.gasColumnType?.message}</span>
						</div>
						<div className="form-group">
							<label>{t('POS')}<span className="required-mark">*</span></label>
							<Controller
								name="posId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										menuPosition="absolute"
										options={poses}
										value={poses.find(option => option.id === getValues('posId')) || ''}
										onChange={(option) => select(option, 'posId')}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>}
							/>
							<span className="text-danger text-sm">{errors.posId?.message}</span>
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2"
								onClick={() => closeModal()}>
								{t('CANCEL')}
							</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
